import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Row,
  Col,
  Divider,
  Tooltip,
} from 'antd';
import { 
  EditOutlined,
  } from '@ant-design/icons';
import { userContext } from '../../App';
import TagList from '../TagList';
import TagManager from '../TagManager';
import { openMessage } from '../../utilities/messages';
import Loading from '../Loading';


function UserManageTriggers() { 

  const {signedInUser} = useContext(userContext);
  const [favoriteTagModalOpen, setfavoriteTagModalOpen] = useState(false)
  const [blockedTagModalOpen, setblockedTagModalOpen] = useState(false)

  function handleEditFavoriteTagsClick() {
    if (!signedInUser.email_verified) {
      openMessage('warning', 'You must verify your email address before using this feature.', 3);
      return
    }
    setfavoriteTagModalOpen(!favoriteTagModalOpen);
  }

  function handleEditBlockedTagsClick() {
    if (!signedInUser.email_verified) {
      openMessage('warning', 'You must verify your email address before using this feature.', 3);
      return
    }
    setblockedTagModalOpen(!blockedTagModalOpen);
  }

  if (!signedInUser.loaded) {
    return (
      <Loading />
    )
  }

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Manage Triggers | ASMRVIDEODB</title>
        <meta name="description" content="Manage your favorite and blocked triggers." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <TagManager
        visible={favoriteTagModalOpen} 
        toggle={handleEditFavoriteTagsClick} 
        tags={signedInUser.favorite_tags}
        modalTitle='Update Favorite Triggers'
        type='favorite'
      />
      <TagManager
        visible={blockedTagModalOpen} 
        toggle={handleEditBlockedTagsClick} 
        tags={signedInUser.blocked_tags} 
        modalTitle='Update Blocked Triggers'
        type='blocked'
      />
      <h4>Manage Triggers</h4>
      <Divider orientation="left">Favorite Triggers</Divider>
      <Row className='ml-3' gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 4, sm: 8, md: 12 }]}>
      <Col span={22}>
        <TagList tags={signedInUser.favorite_tags} />
      </Col>
      <Col className='video-icons-container' span={1}>
        <Tooltip title="Edit"><EditOutlined onClick={handleEditFavoriteTagsClick} /></Tooltip>
      </Col>
      </Row>
      <Divider orientation="left">Blocked Triggers</Divider>
      <Row className='ml-3' gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 4, sm: 8, md: 12 }]}>
        <Col span={22}>
          <TagList tags={signedInUser.blocked_tags} />
        </Col>
        <Col className='video-icons-container' span={1}>
          <Tooltip title="Edit"><EditOutlined onClick={handleEditBlockedTagsClick} /></Tooltip>
        </Col>
      </Row>
    </div>
  )
}

export default UserManageTriggers;