import React from 'react';

const UserFormLayout = (props) => 
  <div className="layout-user-form-container">
    <div className="layout-user-form-content pt-4">
      {props.children}
    </div>
  </div>;


const ContentListLayout = (props) => 
  <div className="layout-content-list-container">
    <div className="layout-content-list-content pt-4">
      {props.children}
    </div>
  </div>;

const HomeLayout = (props) => 
  <div className="layout-home">
      {props.children}
  </div>;

export { UserFormLayout, ContentListLayout, HomeLayout };