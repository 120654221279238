import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import { InputNumber, Result, Button } from 'antd';
import { openMessage } from '../../utilities/messages';
import { POSTWithKey, POSTAnon } from '../../utilities/apiCalls';
import Loading from '../Loading';
import apiURLs from '../../apiURLs';
import { userContext, authKeyContext } from '../../App';
import manResting from '../../images/man-headphones-resting.svg';

function Donate() { 

  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const [donationAmount, setDonationAmount] = useState(0);
  const [buttonLoaded, setButtonLoaded] = useState(false);
  const [donationSuccess, setDonationSuccess] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const onAmountChange = value => {
    setDonationAmount(value);
  };

  function revealButton() {
    setButtonLoaded(true);
  };

  function handleHomeClick() {
    history.push("/");
  }

  function onFinish(details, data) {
    async function sendDonationInfo() {
      let donationFailure = false;
      let fetchRequest;
      let requestData = {
        order_id: data.orderID,
        amount: details.purchase_units[0].amount.value,
        first_name: details.payer.name.given_name,
        last_name: details.payer.name.surname,
        email: details.payer.email_address,
       }
      if (signedInUser.accesskey) {
        fetchRequest = POSTWithKey(requestData, signedInUser.accesskey, apiURLs.donation);
      } else {
        fetchRequest = POSTAnon(requestData, apiURLs.donation);
      }
      await fetchRequest
        .catch(error => {
          donationFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
              // send again but anonymous this time?
            } else {
              openMessage('error', error.detail);
            }
          }
        })
      if (!donationFailure) {
        setDonationSuccess(true);
      }
    }

    if (signedInUser.loaded) {
      sendDonationInfo();
    }
  }

  if (!signedInUser.loaded || !signedInUser.username) {
    return (
      <Loading />
    )
  }

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
          <title>Donate | ASMRVIDEODB</title>
          <meta name="description" content="Donate to show your support for ASMRVIDEODB." />
          <link rel="canonical" href="https://www.asmrvideodb.com/donate" />
      </Helmet>
      {donationSuccess ? 
        <Result
          status="success"
          title="Donation Received!"
          subTitle="Thank you so much for your support!"
          extra={[
            <Button type="primary" key="home" onClick={handleHomeClick}>
              Home
            </Button>
          ]}
        />
        :
        <>
          <h2 className='utilities-text-center shadowed-text'>Make a Donation</h2>
          <div style={{width: '75%', margin: '1.5em auto'}}>
            <div className='svg-container'>
              <img className='svg-content' src={manResting} alt='Man listening to headphones while resting on a pillow.'/>
            </div>
          </div>
          <div>
            <p>ASMRVIDEODB relies on donations to keep our site up and running. If you have found our site beneficial please consider making a contribution.</p>
            <p>Your donation will go towards:</p>
            <ul className='utilities-indented-list mb-4'>
              <li>Hosting Costs</li>
              <li>Upgrading Infrastructure</li>
              <li>Development Time</li>
            </ul>
          </div>
          <p>The minimum donation amount is $1.</p>
          <InputNumber 
            className="mb-3 w-100" 
            placeholder="Donation Amount" 
            size="large" 
            min={1} 
            precision={2} 
            onChange={onAmountChange}
          />
          <div className={buttonLoaded ? 'utilities-hide' : ''}>
            <Loading />
          </div>
          <PayPalButton
            className={buttonLoaded ? '' : 'utilities-hide'}
            amount={donationAmount ? donationAmount : '1'}
            onButtonReady={revealButton}
            shippingPreference="NO_SHIPPING"
            onSuccess={(details, data) => {
              onFinish(details, data);
            }}
            options={{
              // clientId: "AbmCVUX-n4dUatGYs_zB5TCpgUXzps5cfTZG9JSynIMRQ-xh_4Xn_7kxmPEj0aHztq9QGp39JQqlXiCz"
              clientId: "AXaLisajmCR9LQDun-lPSZT82_EkvkpgFHJiBl17a3RLADKPGDevD49pJ-hGcHkeAXoHCIG9kcoq4zCR"
            }}
          />
        </>
      }
      </div>
  )
}

export default Donate;