import {handleAPIErrors as handleErrors} from "./handleAPIErrors" 

function setKeyWithExpiration(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

function getKeyOrExpire(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return False
	if (!itemStr) {
		return false
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return False
		localStorage.removeItem(key)
		return false
	}
	return item.value
}

async function getAccessKey(login, url, timeout=9000) {
	let controller = new AbortController();
	let timeoutController = setTimeout(() => controller.abort(), timeout);
  let accessKey = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(login),
	})
	.then(handleErrors)
	.then(response => response.json())
	.then(response => response.key)
	clearTimeout(timeoutController);
	return await accessKey;
}

async function requestUserInfo(key, url, timeout=12000) {
	let controller = new AbortController();
	let timeoutController = setTimeout(() => controller.abort(), timeout);
	let authHeader = 'Token ' + key
	let userInfo = await fetch(url, {
		method: 'GET',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Authorization': authHeader
    },
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await userInfo;
}


async function requestLogout(key, url, timeout=12000) {
	let controller = new AbortController();
	let timeoutController = setTimeout(() => controller.abort(), timeout);
	let authHeader = 'Token ' + key
	let logout = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Authorization': authHeader
    },
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await logout;
}

async function requestRegistration(info, url, timeout=20000) {
	let controller = new AbortController();
	let timeoutController = setTimeout(() => controller.abort(), timeout);
  let registrationKey = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(info),
	})
	.then(handleErrors)
	.then(response => response.json())
	.then(response => response.key)
	clearTimeout(timeoutController);
	return await registrationKey 
}

async function requestUpdateUser(key, info, url, timeout=9000) {
	let controller = new AbortController();
	let timeoutController = setTimeout(() => controller.abort(), timeout);
	let authHeader = 'Token ' + key
  let updateUserResponse = await fetch(url, {
		method: 'PUT',
		mode: 'cors',
		signal: controller.signal,
    headers: {
			'Content-Type': 'application/json',
			'Authorization': authHeader
    },
    body: JSON.stringify(info),
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await updateUserResponse 
}

function setUserInfo(user, apikey) {
	const now = new Date()
	const item = {
		pk: user.pk,
		username: user.username,
		first_name: user.first_name,
		last_name: user.last_name,
		is_moderator: user.is_moderator,
		age: user.age,
		gender: user.gender,
		email: user.email,
		email_verified: user.email_verified,
		favorite_videos: user.favorite_videos,
		blocked_videos: user.blocked_videos,
		favorite_artists: user.favorite_artists,
		blocked_artists: user.blocked_artists,
		favorite_tags: user.favorite_tags,
		blocked_tags: user.blocked_tags,
		avatar: user.avatar,
		accesskey: apikey,
		loaded: true,
		creation: now.getTime()
	}
	localStorage.setItem('user', JSON.stringify(item))
}

function getUserInfo() {
	const itemStr = localStorage.getItem('user')
	if (!itemStr) {
		return false
	}
	const item = JSON.parse(itemStr)
	return item
}

export {setKeyWithExpiration,getKeyOrExpire,getAccessKey,requestUserInfo,requestUpdateUser,requestLogout,requestRegistration,setUserInfo,getUserInfo}