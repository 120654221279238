import React from 'react';
import { Helmet } from 'react-helmet';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

function FourOFour() {

  const history = useHistory();

  function handleClick() {
    history.push("/");
  }

  return (
    <>
      <Helmet>
        <title>404 - This Page Does Not Exist</title>
        <meta name="description" content="404 this page does not exist." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle="This page does not exist."
        extra={<Button type="primary" onClick={handleClick}>Back Home</Button>}
      />
    </>
  )
}

export default FourOFour;