import React, { useContext, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { userContext } from '../../App';
import { setUserInfo, getUserInfo } from '../../utilities/authentication';
import { useHistory } from "react-router-dom";
import sleep from '../../utilities/sleep';
import { Result } from 'antd';

function EmailVerified() {

  const {signedInUser, setSignedInUser} = useContext(userContext);
  const history = useHistory();

  useEffect(() => {
    async function updateEmailverified() {
      if (signedInUser.accesskey && !signedInUser.email_verified) {
        let user = signedInUser;
        user.email_verified = true;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
        await sleep(3500);
        history.push('/');
      } else {
        await sleep(3500);
        history.push('/');
      }
    }
    
    updateEmailverified();
  }, [signedInUser.accesskey, signedInUser.email_verified, history, setSignedInUser, signedInUser])

  return (
    <>
      <Helmet>
        <title>Your Email Has Been Verified!</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Result
        status="success"
        title="Your email has been verified!"
        subTitle="Please wait while you are redirected..."
      />
    </>
  )
}

export default EmailVerified;