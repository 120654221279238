import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { setKeyWithExpiration, getAccessKey } from '../utilities/authentication';
import { openMessage } from '../utilities/messages';
import apiURLs from '../apiURLs';

function DropdownLogin(props) {

  const [form] = Form.useForm();
  const [loadingLogin, setLoadingLogin] = useState(false);

  const onFinish = values => {
    async function getAccessKeyOnLogin() { 
      let loginFailure = false;
      let accessKey = await getAccessKey({username: values.username, password: values.password}, apiURLs.login)
      .catch(error => {
        loginFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          form.setFields([{name: 'username', errors: error['non_field_errors']}]);
        }
      });
      setLoadingLogin(false);
      if (!loginFailure) {
        props.authentication.setAuthenticationKey(accessKey);
        let rememberPeriod = (values.remember ? 2678400000 : 432000);
        setKeyWithExpiration('accessKey', accessKey, rememberPeriod);
      }
    }
    setLoadingLogin(true);
    getAccessKeyOnLogin();
  };

  const onFinishFailed = errorInfo => {
    console.log(errorInfo);
    setLoadingLogin(false);
  };

  return(
    <div className="nav-dropdown-login-container">
      <Form
        form={form}
        name="normal_login"
        className="nav-dropdown-login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
          >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox style={{color: 'whitesmoke'}}>Remember me</Checkbox>
        </Form.Item>

          <Link to="/reset-password" className="login-form-forgot">Forgot Password?</Link>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loadingLogin}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default DropdownLogin;
