import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Form,
  Input,
  Tooltip,
  Select,
  Button,
  Divider
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { POSTWithKey } from '../../utilities/apiCalls';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import { userContext, authKeyContext } from '../../App';
import apiURLs from '../../apiURLs';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function ArtistSubmit() { 

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  const onFinish = values => {
    async function submitArtist() {
      let submissionFailure = false
      let submissionStatus = await POSTWithKey({
        name: values.name,
        youtube_id: values.youtube_id,
        gender: values.gender,
       }, signedInUser.accesskey, apiURLs.submitArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              if (error.detail) {
                openMessage('error', error.detail);
              }
              form.setFields(handleServerErrors(error));
            }
          }
        })
      setLoading(false);
      if (!submissionFailure) {
        openMessage('success', `You have successfully submitted ${submissionStatus.name}! A staff member will review your submission shortly.`); 
        form.resetFields();
      }
    }
    
    setLoading(true);
    submitArtist();
  };

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Submit an Artist | ASMRVIDEODB</title>
        <meta name="description" content="Submit an artist to be included in the ASMR Video Database." />
        <link rel="canonical" href="https://www.asmrvideodb.com/submit-artist" />
      </Helmet>
      <h3 className='shadowed-text'>
        Submit New ASMR Artist
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
      {...formItemLayout}
      form={form}
      name="submitArtist"
      onFinish={onFinish}
      className="mt-3"
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Channel Name"
        rules={[
          {
            required: true,
            message: "Please input the artist's name.",
            whitespace: true
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="youtube_id"
        label={
          <span>
            Channel ID&nbsp;
            <Tooltip title="The ID can be found in the url of the artist's channel page. www.youtube.com/channel/IDWillBeHere">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        rules={[{ required: true,
                  message: 'Please input the channel ID',
                  whitespace: true
              },
            ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
          name="gender"
          label="Gender"
        >
          <Select style={{ width: 120 }}>
            <Option value=""></Option>
            <Option value="M">Male</Option>
            <Option value="F">Female</Option>
          </Select>
        </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
  )
}

export default ArtistSubmit;