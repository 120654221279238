import React, { useState, useContext } from 'react';
import { Button } from 'antd';
import { PUTWithKey } from '../utilities/apiCalls'
import { userContext } from '../App';
import apiURLs from '../apiURLs';
import { openMessage } from '../utilities/messages';

function ModerateSubmittedArtistBox(props) {

  const {signedInUser} = useContext(userContext);
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  function removeEntry() {
    const filteredList = props.artists.filter(artist => artist.youtube_id !== props.id);
    props.setArtists({loading: false, error: {hasError: false, type: '', statusCode: '', description: ''}, content: filteredList});
  }

  function onApproval(event) {
    async function approveArtist() {
      let approvalFailure = false
      let approvedEntry = await PUTWithKey({
        approved: true,
        gender: props.gender,
        name: props.name,
        youtube_id: props.id,
       }, signedInUser.accesskey, `${apiURLs.moderatePotentialArtists}${props.id}/`, 30000)
        .catch(error => {
          approvalFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            openMessage('error', error.statusText);
          }
        })
      setApproveLoading(false)
      if (!approvalFailure) {
        removeEntry();
        openMessage('success', `${approvedEntry.name} has successfully been added to the database.`); 
      }
    }
    setApproveLoading(true);
    approveArtist();
  }

  function onDenial(event) {
    async function denyArtist() {
      let denialFailure = false
      let deniedEntry = await PUTWithKey({
        denied: true,
        gender: props.gender,
        name: props.name,
        youtube_id: props.id,
       }, signedInUser.accesskey, `${apiURLs.moderatePotentialArtists}${props.id}/`)
        .catch(error => {
          denialFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            openMessage('error', error.statusText);
          }
        })
      setDenyLoading(false)
      if (!denialFailure) {
        removeEntry();
        openMessage('success', `${deniedEntry.name} has successfully been denied.`); 
      }
    }
    setDenyLoading(true);
    denyArtist();
  }

  const youtube_link = `https://www.youtube.com/channel/${props.id}`

  return (
    <div className='submitted-artist-mod-box-wrapper'>
      <h4>{props.name}</h4>
      <p>Gender: {props.gender}</p>
      <a href={youtube_link} target="_blank" rel='noreferrer'>Open Link</a>
      <br />
      <Button type="primary" onClick={onApproval} loading={approveLoading}>Accept Button</Button>
      <Button type="primary" danger onClick={onDenial} loading={denyLoading}>Delete Button</Button>
    </div>
  )
}

export default ModerateSubmittedArtistBox