import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, List, Input, Comment, Avatar } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import GenericComment from './GenericComment';
import ReportManager from './ReportManager';
import { GETAnon, POSTWithKey } from '../utilities/apiCalls';
import { userContext, authKeyContext } from '../App';
import { openMessage } from '../utilities/messages';
import apiURLs from '../apiURLs';
import Error from './Error';
import Loading from './Loading';
import avatarm1 from '../images/avatar-m1.png';
import avatarm2 from '../images/avatar-m2.png';
import avatarm3 from '../images/avatar-m3.png';
import avatarf1 from '../images/avatar-f1.png';
import avatarf2 from '../images/avatar-f2.png';
import avatarf3 from '../images/avatar-f3.png';


const { TextArea } = Input;

function CommentList(props) {
  /* props: 
  videoID - ID of the video
  */ 
  const{videoID} = props;

  const [submitCommentLoading, setSubmitCommentLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [loadCommentCount, setLoadCommentCount] = useState(10);
  const [comments, setComments] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: []
  });
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const [form] = Form.useForm();

  useEffect(() => {
    async function getCommentInfo() {
      let getCommentInfoFailure = false;
      let requestInfo = {
        model_name: 'Video',
        model_id: videoID,
        app_name: 'api'
      }
      let retrievedCommentInfo = await GETAnon(`${apiURLs.getComments}?${new URLSearchParams(requestInfo)}`)
      .catch(error => {
        getCommentInfoFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          setComments({
            loading: false,
            error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
            content: false
          });
        }
      })
      if (!getCommentInfoFailure) {
        setComments({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: retrievedCommentInfo
        })
      }
    }

    getCommentInfo();
  }, [videoID])


  function handleReplySubmit(values) {
    async function submitComment() {
      let submissionFailure = false
      let requestInfo = {
        model_name: 'Video',
        model_id: videoID,
        app_name: 'api',
        parent_id: 0
      }
      let submittedComment = await POSTWithKey({
        content: values.replyBox
       }, signedInUser.accesskey, `${apiURLs.postComment}?${new URLSearchParams(requestInfo)}`)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
        setSubmitCommentLoading(false);
      if (!submissionFailure) {
        submittedComment.reactions = {"likes":0,"dislikes":0,"users":{"likes":[],"dislikes":[]}}
        let updatedCommentList = JSON.parse(JSON.stringify(comments));
        updatedCommentList.content.push(submittedComment);
        if (loadCommentCount <= updatedCommentList.content.length) {
          setLoadCommentCount(updatedCommentList.content.length);
        }
        setComments(updatedCommentList);
        form.resetFields();
      }
    }
    
    setSubmitCommentLoading(true);
    submitComment();
  };

  function handleReportClick() {
    setReportModalOpen(!reportModalOpen);
  }

  function onLoadMore() {
    setLoadCommentCount(loadCommentCount + 10);
  }

  if (comments.error.hasError) {
    return (
      <Error type={comments.error.type} statusCode={comments.error.statusCode} description={comments.error.description} />
    )
  }

  if (comments.loading) {
    return (
      <Loading />
    )
  }

  let avatarImage;
  switch (signedInUser.avatar) {
    case 1:
      avatarImage = avatarm1;
      break;
    case 2:
      avatarImage = avatarm2;
      break;
    case 3:
      avatarImage = avatarm3;
      break;
    case 4:
      avatarImage = avatarf1;
      break;
    case 5:
      avatarImage = avatarf2;
      break;
    case 6:
      avatarImage = avatarf3;
      break;
    default:
      avatarImage = avatarm1;
  } 

  const loadMore=
    loadCommentCount < comments.content.length ?
      <div className='generic-comment-load-more-container'>
        <button className='button-view-more' onClick={onLoadMore}>Load More Comments{<CaretRightOutlined className='caret-right' />}</button>
      </div>
      :
      null

  const replyBox = 
    signedInUser.email_verified ?
      <Comment
        className='generic-comment-reply-box'
        avatar={
          <Avatar
            src={avatarImage}
            alt="Avatar"
          />
        }
        content={
          <Form
            name="replyForm"
            onFinish={handleReplySubmit}
            form={form}
          >
            <Form.Item name="replyBox">
              <TextArea rows={3} placeholder="Write your reply..." />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={submitCommentLoading} type="primary">
                Comment
              </Button>
            </Form.Item>
          </Form>
        }
      />
      :
      null

  if (!comments.content.length) {
    return (
      <>
        <i className='pl-2'>No comments yet!</i>
        {replyBox}
      </>
    )
  }

  return(
    <>
      <ReportManager
        visible={reportModalOpen} 
        toggle={handleReportClick} 
        objectID={selectedComment}
        mask = {true}
        modalTitle='Report This Comment'
        type='comment'
      />
      <h4 className='utilities-twoem mb-1'>{comments.content.length > 1 ? `${comments.content.length} Comments` : `1 Comment`}</h4>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={comments.content.slice(0, loadCommentCount)}
        loadMore={loadMore}
        renderItem={item => (
          <li>
            <GenericComment
              depth={0}
              commentID={item.id}
              modelID={videoID}
              avatar={item.user.avatar}
              author={item.user.username}
              authorID={item.user.id}
              content={item.content}
              timePosted={item.posted}
              timeEdited={item.edited}
              reactions={item.reactions}
              childrenComments={item.replies}
              manageReportModel={{setReportModalOpen: setReportModalOpen, setSelectedComment: setSelectedComment}}
            />
          </li>
        )}
      />
      {replyBox}
    </>
  )
}

export default CommentList;