import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Divider,
  Modal
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { requestRegistration, setKeyWithExpiration } from '../../utilities/authentication';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import TermsOfService from '../TermsOfService';
import apiURLs from '../../apiURLs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function UserRegister(props) { 

  const [form] = Form.useForm();
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  function toggleModal() {
    setModalVisible(!modalVisible);
  }

  const onFinish = values => {
    async function registerUser() {
      let registrationFailure = false
      let registrationAuthKey = await requestRegistration({
        username: values.username,
        password1: values.password1,
        password2: values.password2,
        email: values.email
       }, apiURLs.register)
        .catch(error => {
          registrationFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            form.setFields(handleServerErrors(error));
          }
        })
      setLoadingRegister(false);
      if (!registrationFailure) {
        openMessage('success', 'You have successfully created an account!'); 
        props.setAuthKey(registrationAuthKey);
        setKeyWithExpiration('accessKey', registrationAuthKey, 432000);
      }
    }
    
    setLoadingRegister(true);
    registerUser();
  };

  if (props.redirect) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Register | ASMRVIDEODB</title>
        <meta name="description" content="Register for a new account on ASMRVIDEODB. Sign up to start finding and tracking your favorite ASMR artists today!" />
        <link rel="canonical" href="https://www.asmrvideodb.com/register" />
      </Helmet>
      <Modal
        width='600'
        visible={modalVisible}
        onCancel={toggleModal}
        footer={null}
      >
        <TermsOfService />
      </Modal>
      <h3 className='shadowed-text'>
        Create a New Account
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mt-3"
        scrollToFirstError
      >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'This is not a valid E-mail address!',
          },
          {
            required: true,
            message: 'Please input your E-mail address!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="username"
        label={
          <span>
            Username&nbsp;
            <Tooltip title="What do you want others to call you?">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        rules={[{ required: true, message: 'Please input your username!', whitespace: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password1"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="password2"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password1') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('You must accept the usage agreement.'),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          I have read the <button className='utilities-link-button' onClick={toggleModal}>terms of service</button>
        </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loadingRegister}>
          Register
        </Button>
      </Form.Item>
    </Form>
    </div>
  )
}

export default UserRegister;