import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {useParams} from "react-router-dom";
import VideoList from '../VideoList';

const { Option } = Select;

function SearchResults(props) {
  /* props: 
  searchLoading - Manage search bar loading indicator,
  */ 
  const {searchLoading} = props;

  const { term } = useParams();
  const [videosRetrieved, setVideosRetrieved] = useState(false);
  const [sortType, setSortType] = useState('');

  useEffect(() => {
    if (videosRetrieved) {
      searchLoading.setSearchLoading(false);
      setVideosRetrieved(false);
    }
    window.scrollTo(0, 0);
  }, [videosRetrieved, searchLoading])

  function changeSortType(value) {
    setSortType(value);
  }

  return (
    <>
      <Helmet>
        <title>{`Search Results - ${term} | ASMRVIDEODB`}</title>
        <meta name="description" content={`ASMRVIDEODB Search Results for ${term}.`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Row align='bottom' justify='end'>
        <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18}>
          <h3 className="userFavorites-heading">Results for <i>{term}</i></h3>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}>
          <span>Sort by: </span>
          <Select
            className="searchResults-sort-select"
            defaultValue="" 
            showArrow={true}
            suffixIcon={<DownOutlined />}
            onChange={changeSortType}
          >
            <Option value="">Relevance</Option>
            <Option value="date">Release Date</Option>
            <Option value="favorites">Most Favorites</Option>
            <Option value="tingles">Most Tingles</Option>
          </Select>
        </Col>
      </Row>
        <div className="utilities-divider"> <span></span></div>
      <VideoList pagination={true} type={'search'} term={term} sortType={sortType} retrievalStatus={setVideosRetrieved} />
    </>
  )
}

export default SearchResults;
