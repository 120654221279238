import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Result, Button  } from 'antd';

function Error(props) {

  const history = useHistory();

  function handleClick() {
    history.push("/");
  }

  if (props.statusCode === 404) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="This page does not exist."
        extra={<Button type="primary" onClick={handleClick}>Back Home</Button>}
      />
    )
  }

  if (props.statusCode === 403) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Button type="primary" onClick={handleClick}>Back Home</Button>}
      />
    )
  }

  if (props.statusCode === 500) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Something went wrong. Please try again in a little while."
        extra={<Button type="primary" onClick={handleClick}>Back Home</Button>}
      />
    )
  }

  return(
    <Alert 
      className='utilities-generic-error'
      message={props.type ? props.type : "Error"}
      showIcon
      description={props.description ? `${props.statusCode} ${props.description}` : 'We encountered a network error. Please let us know if this problem persists.'}
      type="error"
    />
  )
}

export default Error;
