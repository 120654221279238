const tagList = {
  Category: [
    'Intentional',
    'Unintentional'
  ],

  Gender: [
    'Female', 
    'Male'
  ],

  Verbal: [
    'Ear to Ear Whispering',
    'No Talking',
    'Onomatopoeia',
    'Personal Attention',
    'Rambling',
    'Roleplay',
    'Singing',
    'Soft Talking',
    'Strange',
    'Trigger Words',
    'Unintelligible Whispering',
    'Whispering',
  ],

  Auditory: [
    'Ambient Sounds',
    'Brushing',
    'Buzzing',
    'Chalk on Chalkboard',
    'Chewing',
    'Clicking',
    'Crinkling',
    'Cutting Hair',
    'Cutting Paper',
    'Ear Blowing',
    'Ear Cupping',
    'Ear Cleaning',
    'Ear Massage',
    'Ear Scratching',
    'Eating',
    'Fabric Sounds',
    'Fizzing',
    'Flame',
    'Foaming',
    'Hairdryer',
    'Heartbeat',
    'Humming',
    'Kiss Sounds',
    'Lathering',
    'Layered Sounds',
    'Liquid Sounds',
    'Microphone Scratching',
    'Motor Humming',
    'Mouth Sounds',
    'Purring',
    'Ringing',
    'Rubber Gloves',
    'Scratching',
    'Slime Sounds',
    'Snipping',
    'Spraying',
    'Sticky Fingers',
    'Tapping',
    'Ticking',
    'Typing',
    'Water Drops',
    'Writing',
  ],

  Visual: [
    'Assembling',
    'Camera Brushing',
    'Camera Touching',
    'Costume',
    'Drawing',
    'Ear Brushing',
    'Examination',
    'Follow the Light',
    'Gameplay',
    'Hair Play',
    'Hair Washing',
    'Hand Movements',
    'Massage',
    'Page Turning',
    'Painting',
    'Physical Touch',
    'Plucking',
    'Reiki',
    'Scalp Massage',
    'Teaching',
    'Tutorial',
    'Unboxing',
  ],

  Language: [
    'English', 
    'Arabic', 
    'Chinese', 
    'Dutch', 
    'French', 
    'German', 
    'Indian', 
    'Indonesian', 
    'Italian', 
    'Japanese', 
    'Korean', 
    'Polish', 
    'Portuguese', 
    'Romanian', 
    'Russian', 
    'Spanish', 
    'Tagalog',
    'Thai',
    'Turkish', 
    'Ukrainian', 
    'Vietnamese', 
  ],

  Technical: [
    'Binaural',
    'Stereo',
    'Lofi',
    'Quick',
  ]
}

const tagColors = {
    Intentional: '#3D9970',
    Unintentional: '#3D9970',
    Female: '#39CCCC', 
    Male: '#39CCCC', 
    Ear_to_Ear_Whispering: '#901450',
    No_Talking: '#901450',
    Onomatopoeia: '#901450',
    Personal_Attention: '#901450',
    Rambling: '#901450',
    Roleplay: '#901450',
    Singing: '#901450',
    Soft_Talking: '#901450',
    Strange: '#901450',
    Trigger_Words: '#901450',
    Unintelligible_Whispering: '#901450',
    Whispering: '#901450',
    Ambient_Sounds: '#FF851B',
    Brushing: '#FF851B',
    Buzzing: '#FF851B',
    Chalk_on_Chalkboard: '#FF851B',
    Chewing: '#FF851B',
    Clicking: '#FF851B',
    Crinkling: '#FF851B',
    Cutting_Hair: '#FF851B',
    Cutting_Paper: '#FF851B',
    Ear_Blowing: '#FF851B',
    Ear_Cupping: '#FF851B',
    Ear_Cleaning: '#FF851B',
    Ear_Massage: '#FF851B',
    Ear_Scratching: '#FF851B',
    Eating: '#FF851B',
    Fabric_Sounds: '#FF851B',
    Fizzing: '#FF851B',
    Flame: '#FF851B',
    Foaming: '#FF851B',
    Hairdryer: '#FF851B',
    Heartbeat: '#FF851B',
    Humming: '#FF851B',
    Kiss_Sounds: '#FF851B',
    Lathering: '#FF851B',
    Layered_Sounds: '#FF851B',
    Liquid_Sounds: '#FF851B',
    Microphone_Scratching: '#FF851B',
    Motor_Humming: '#FF851B',
    Mouth_Sounds: '#FF851B',
    Purring: '#FF851B',
    Ringing: '#FF851B',
    Rubber_Gloves: '#FF851B',
    Scratching: '#FF851B',
    Slime_Sounds: '#FF851B',
    Snipping: '#FF851B',
    Spraying: '#FF851B',
    Sticky_Fingers: '#FF851B',
    Tapping: '#FF851B',
    Ticking: '#FF851B',
    Typing: '#FF851B',
    Water_Drops: '#FF851B',
    Writing: '#FF851B',
    Assembling: '#e91cbb',
    Camera_Brushing: '#e91cbb',
    Camera_Touching: '#e91cbb',
    Costume: '#e91cbb',
    Drawing: '#e91cbb',
    Ear_Brushing: '#e91cbb',
    Examination: '#e91cbb',
    Follow_the_Light: '#e91cbb',
    Gameplay: '#e91cbb',
    Hair_Play: '#e91cbb',
    Hair_Washing: '#e91cbb',
    Hand_Movements: '#e91cbb',
    Massage: '#e91cbb',
    Page_Turning: '#e91cbb',
    Painting: '#e91cbb',
    Physical_Touch: '#e91cbb',
    Plucking: '#e91cbb',
    Reiki: '#e91cbb',
    Scalp_Massage: '#e91cbb',
    Teaching: '#e91cbb',
    Tutorial: '#e91cbb',
    Unboxing: '#e91cbb',
    English: '#f5453b', 
    Arabic: '#f5453b', 
    Chinese: '#f5453b', 
    Dutch: '#f5453b', 
    French: '#f5453b', 
    German: '#f5453b', 
    Indian: '#f5453b', 
    Indonesian: '#f5453b', 
    Italian: '#f5453b', 
    Japanese: '#f5453b', 
    Korean: '#f5453b', 
    Polish: '#f5453b', 
    Portuguese: '#f5453b', 
    Romanian: '#f5453b', 
    Russian: '#f5453b', 
    Spanish: '#f5453b', 
    Tagalog: '#f5453b',
    Thai: '#f5453b',
    Turkish: '#f5453b', 
    Ukrainian: '#f5453b', 
    Vietnamese: '#f5453b', 
    Binaural: '#7437bb',
    Stereo: '#7437bb',
    Lofi: '#7437bb',
    Quick: '#7437bb',
}

export {tagList, tagColors};