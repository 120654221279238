import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import {
  Row,
  Col,
  Image
  } from 'antd';
import womanRestingHeadphones from '../../images/woman-resting-with-phone-headphones.jpg';
import dogRestingHeadphones from '../../images/relaxing-dog-headphones.jpg';

function About() {

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1200px)'
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Helmet>
        <title>About | ASMRVIDEODB</title>
        <meta name="description" content="What does ASMR mean: ASMR is characterized by a pleasurable tingling sensation in the scalp and sometimes the back of the neck and upper spine precipitated by a stimuli." />
        <link rel="canonical" href="https://www.asmrvideodb.com/about" />
      </Helmet>
      <Row className='mt-3'>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={12} className='mr-2'>
          <h3>What is <span className='home-title-color'>ASMR?</span></h3>
          <p>Autonomous sensory meridian response, or ASMR, is characterized by a pleasurable tingling sensation in the scalp and sometimes the back of the neck and upper spine. It is precipitated by a stimuli, often referred to as a "trigger."  Common ASMR triggers include:</p>
          <ul className='utilities-indented-list'>
            <li>Soft spoken or whispering voice.</li>
            <li>Scratching or tapping sounds.</li>
            <li>Close personal attention.</li>
            <li>Quiet sounds resulting from a mundane task such as typing or flipping a page.</li>
            <li>Slow hand movements.</li>
          </ul>
          <p>Not everyone experiences ASMR and the cause of the phenomenon is still unclear. In spite of this, a sizeable community has been built around it -- with hundreds of channels on Youtube dedicated to creating videos containing a large variety of triggers.  Many people who don't experience the "tingles" aspect of ASMR still enjoy these videos for their relaxing nature, listening to them as a sleep aid or destresser.</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={11}>
          <Image
              className='about-image'
              preview={true}
              src={womanRestingHeadphones}
              alt='A woman listening to her phone with headphones while resting on the bed.'
            />
        </Col>
      </Row>
      <Row className='my-5'>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11} className='mr-4' order={isLargeScreen ? 0 : 1}>
          <Image
              className='about-image'
              preview={true}
              src={dogRestingHeadphones}
              alt='A golden retriever laying on a pillow with headphones on.'
            />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} order={isLargeScreen ? 1 : 0}>
          <h3>What is <span className='home-title-color'>ASMRVIDEODB?</span></h3>
          <p>ASMR Video Database collects information about videos from numerous ASMR content creators.  This allows us to provide our users with a convenient and succinct way to find and track ASMR videos that suit their personal preferences.  Features on our site include:</p>
          <ul className='utilities-indented-list'>
            <li>Favoriting videos and artists.</li>
            <li>Tag videos based on what triggers they contain.</li>
            <li>Personalized recommendations based on your favorite triggers.</li>
            <li>Filter videos that contain triggers you dislike.</li>
            <li>Aggregated lists of the most popular ASMR videos.</li>
          </ul>
          <p>We rely on our users to aid us in tagging videos and alerting us of any ASMR artists we may have overlooked. Please don't hesitate to contribute!</p>
        </Col>
      </Row>
    </>
  )
}

export default About;