import React, { useState, useEffect, useContext } from 'react';
import VideoList from './VideoList';
import { GETAnon, GETWithKey } from '../utilities/apiCalls';
import { userContext, authKeyContext } from '../App';
import apiURLs from '../apiURLs';
import { openMessage } from '../utilities/messages';
import Error from './Error';
import Loading from './Loading';

function SimilarVideoList(props) {
  /* props: 
  videoID - ID of the video
  */ 
  const{videoID} = props;

  const [videos, setVideos] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: []
  });
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  useEffect(() => {
    async function getVideos(retry = false) {
      let getVideosFailure = false;
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = GETWithKey(signedInUser.accesskey, `${apiURLs.listSimilarVideos}${videoID}/`);
      } else {
        fetchRequest = GETAnon(`${apiURLs.listSimilarVideos}${videoID}/`);
      }
      let videoList = await fetchRequest
      .catch(error => {
        getVideosFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again to continue.');
          } else {
            if (!error.status && !retry) {
              getVideos(true);
            } else {
              setVideos({
                loading: false,
                error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
                content: []
              });
            }
          }
        }
      })
      if (!getVideosFailure) {
        setVideos({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: videoList
        });
      }
    }

    if (signedInUser.loaded) {
      getVideos();
    }

  }, [signedInUser.accesskey, signedInUser.loaded, videoID, setAuthenticationKey, setSignedInUser])

  if (videos.error.hasError) {
    return (
      <Error type={videos.error.type} statusCode={videos.error.statusCode} description={videos.error.description} />
    )
  }

  if (videos.loading) {
    return (
      <Loading />
    )
  }
  
  return(
    <VideoList filterBlocked={true} itemsPerPage={8} providedVideos={videos.content}/>
  )
}

export default SimilarVideoList;