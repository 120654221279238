function slugify(string, separator = "-") {
  let newString = string
    .toString()
    .normalize('NFD')                   // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF\u0400-\u04FF ]/g, '')   // remove all chars not letters, numbers, japanese, korean, russian and spaces (to be replaced)
    .replace(/\s+/g, separator);
    if (!newString) {
      newString = 'invalid-title'
    }
    if (newString.charAt(newString.length - 1) === separator) {
      newString = newString.slice(0, -1);
    }
    return newString
};

export default slugify;