export const DEVELOPMENT_MODE = false;

const domain = DEVELOPMENT_MODE ? 'http://127.0.0.1:8000/' : 'https://asmrvideodbapi-x8aeh.ondigitalocean.app/';


const apiURLs = {
  'login': `${domain}db-auth/login/`,
  'logout': `${domain}db-auth/logout/`,
  'register': `${domain}db-auth/registration/`,
  'passwordChange': `${domain}db-auth/password/change/`,
  'passwordResetEmail': `${domain}db-auth/password/reset/`,
  'passwordReset': `${domain}db-auth/password/reset/confirm/`,
  'getUser': `${domain}api/get-user/`,
  'updateUser': `${domain}api/get-user/`,
  'resendEmailConfirmation': `${domain}users/send-confirmation-email/`,
  'submitArtist': `${domain}api/submit-artist/`,
  'listPotentialArtists': `${domain}api/list-potential-artist/`,
  'moderatePotentialArtists': `${domain}api/moderate-potential-artist/`,
  'artistInfo': `${domain}api/artist-info/`,
  'listVideos': `${domain}api/list-videos/`,
  'listSimilarVideos': `${domain}api/list-similar-videos/`,
  'updateFavorite': `${domain}api/update-favorite/`,
  'updateBlocked': `${domain}api/update-blocked/`,
  'updateFavoriteArtist': `${domain}api/update-favorite-artist/`,
  'updateBlockArtist': `${domain}api/update-block-artist/`,
  'updateTingles': `${domain}api/update-tingles/`,
  'updateTags': `${domain}api/update-tags/`,
  'videoInfo': `${domain}api/video-info/`,
  'userFavoriteArtists': `${domain}api/user-favorite-artists/`,
  'UpdateUserTriggers': `${domain}api/update-user-triggers/`,
  'setFlag': `${domain}api/flag/`,
  'contact': `${domain}api/contact/`,
  'getComments': `${domain}api/comments/`,
  'postComment': `${domain}api/comments/create/`,
  'listTopTaggers': `${domain}api/list-top-taggers/`,
  'donation': `${domain}api/donation-transaction/`
}

export default apiURLs;