import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Divider,
} from 'antd';
import { POSTAnon} from '../../utilities/apiCalls';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import apiURLs from '../../apiURLs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function PasswordResetConfirm() { 

  const [resetForm] = Form.useForm();
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const history = useHistory();
  const { uid, token } = useParams();

  const onResetFinish = values => {
    async function resetPassword() {
      let passwordResetFailure = false;
      await POSTAnon({
        uid: uid,
        token: token,
        new_password1: values.new_password1,
        new_password2: values.new_password2,
       }, apiURLs.passwordReset)
        .catch(error => {
          passwordResetFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            resetForm.setFields(handleServerErrors(error));
          }
        })
        setLoadingResetPassword(false);
      if (!passwordResetFailure) {
        openMessage('success', `Your password has been updated!`);
        resetForm.resetFields();
        history.push('/');
      }
    }
    
    setLoadingResetPassword(true);
    resetPassword();
  };


  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Reset Password | SHHASMRDB</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h3 className='shadowed-text'>
        Reset Your Password
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
        {...formItemLayout}
        form={resetForm}
        name="password reset"
        onFinish={onResetFinish}
        className="mt-3"
        scrollToFirstError
      >

      <Form.Item
        name="new_password1"
        label="New Password"
        rules={[
          {
            required: true,
            message: 'Please input your new password.',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="new_password2"
        label="Confirm New Password"
        dependencies={['new_password1']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your new password.',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password1') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingResetPassword}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PasswordResetConfirm;