import React, { useState, useEffect, useContext} from 'react';
import { Helmet } from 'react-helmet';
import ModerateSubmittedArtistBox from '../ModerateSubmittedArtistBox';
import { GETWithKey } from '../../utilities/apiCalls';
import { userContext } from '../../App';
import apiURLs from '../../apiURLs';
import { openMessage } from '../../utilities/messages';
import Error from '../Error';
import Loading from '../Loading';

function SubmittedArtistModeration(props) {

  const [submittedArtists, setSubmittedArtists] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: []
  });
  const {signedInUser} = useContext(userContext);

  useEffect(() => {
    async function getSubmittedArtists() {
      let getSubmittedArtistsFailure = false;
      let potentialArtists = await GETWithKey(signedInUser.accesskey, apiURLs.listPotentialArtists)
      .catch(error => {
        getSubmittedArtistsFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          setSubmittedArtists({
            loading: false,
            error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
            content: []
          });
        }
      })
      if (!getSubmittedArtistsFailure) {
        setSubmittedArtists({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: potentialArtists
        })
      }
    }
    
    if (signedInUser.accesskey) {
      getSubmittedArtists();
    }
  }, [signedInUser])

  if (submittedArtists.error.hasError) {
    return (
      <Error type={submittedArtists.error.type} statusCode={submittedArtists.error.statusCode} description={submittedArtists.error.description} />
    )
  }

  if (submittedArtists.loading) {
    return (
      <Loading />
    )
  }

  const artistList = submittedArtists.content.map(artist => 
    <ModerateSubmittedArtistBox
      key={artist.id}
      id={artist.youtube_id}
      name={artist.name}
      gender={artist.gender}
      artists={submittedArtists.content}
      setArtists={setSubmittedArtists}  
    />
  );

  return (
    <>
      <Helmet>
        <title>{`Submitted Artist Moderation | ASMRVIDEODB`}</title>
        <meta name="description" content="Moderate submitted artists for ASMRVIDEODB" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {artistList.length > 0 ? artistList : 'There are currently no potential artists awaiting moderation.'}
    </>
  )
}

export default SubmittedArtistModeration;