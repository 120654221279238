import React, { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {useParams, useLocation, useHistory } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import TextTruncate from 'react-text-truncate'; 
import ReactPlayer from 'react-player/youtube';
import { 
  Row,
  Col,
  Tooltip,
  Avatar,
  Tabs 
  } from 'antd';
import { 
  StarOutlined,
  StarFilled,
  TagOutlined,
  TagFilled,
  ExclamationCircleOutlined,
  MessageFilled,
  ContainerFilled
  } from '@ant-design/icons';
import { TinglesIcon, TinglesIconFilled, ReplayIcon } from '../CustomIcons';
import { setUserInfo, getUserInfo } from '../../utilities/authentication';
import { GETAnon, PUTWithKey, GETWithKey } from '../../utilities/apiCalls';
import { userContext, authKeyContext } from '../../App';
import apiURLs from '../../apiURLs';
import { openMessage } from '../../utilities/messages';
import slugify from '../../utilities/slugify';
import SimilarVideoList from '../SimilarVideoList';
import CommentList from '../CommentList';
import TagManager from '../TagManager';
import ReportManager from '../ReportManager';
import TagList from '../TagList';
import Error from '../Error';
import Loading from '../Loading';

const { TabPane } = Tabs;

function Video() {

  const { video_id } = useParams();
  const location = useLocation();
  const history = useHistory();
  let {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const [fullDescription, setFullDescription] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [videoOverlayOpen, setVideoOverlayOpen] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoInfo, setVideoInfo] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: false
  });

  const videoPlayer = useRef(null);

  useEffect(() => {
    async function getVideoInfo() {
      let getVideoInfoFailure = false;
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = GETWithKey(signedInUser.accesskey, `${apiURLs.videoInfo}${video_id}/`);
      } else {
        fetchRequest = GETAnon(`${apiURLs.videoInfo}${video_id}/`);
      }
      let retrievedVideoInfo = await fetchRequest
      .catch(error => {
        getVideoInfoFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again.');
          } else {
            setVideoInfo({
              loading: false,
              error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
              content: false
            });
          }
        }
      })
      if (!getVideoInfoFailure) {
        setVideoInfo({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: {videoLink: `https://www.youtube.com/watch?v=${retrievedVideoInfo.video_id}`, ...retrievedVideoInfo}
        })
      }
    }

    window.scrollTo(0,0);
    setVideoOverlayOpen(false);
    if (!location.state) {
      if (signedInUser.loaded) {
        getVideoInfo();
      }
    } else {
      setVideoInfo({
        loading: false,
        error: {hasError: false, type: '', statusCode: '', description: ''},
        content: location.state.video
      })
      window.history.replaceState({}, document.title);
    }
  }, [video_id, signedInUser.loaded, signedInUser.accesskey, location.state, setAuthenticationKey, setSignedInUser])

  function handleArtistClick() {
    history.push(`/artist/${slugify(videoInfo.content.artist_name)}/${videoInfo.content.artist}`)
  }
  function handleInactiveClick() {
    if (!signedInUser.accesskey) {
      openMessage('warning', 'You must be logged in to use this feature.', 3);
    } else if (!signedInUser.email_verified) {
      openMessage('warning', 'You must verify your email address before using this feature.', 3);
    }
  }

  function setVideoOverlay() {
    setVideoOverlayOpen(true);
  }

  function unsetVideoOverlay() {
    setVideoOverlayOpen(false);
  }

  function resetVideo() {
    videoPlayer.current.seekTo(0, 'seconds');
    setVideoPlaying(true);
  }

  function handleTagsClick() {
    setTagModalOpen(!tagModalOpen);
  }

  function handleReportClick() {
    setReportModalOpen(!reportModalOpen);
  }

  function toggleFullDescription() {
    setFullDescription(!fullDescription);
  }

  function handleFavoriteClick() {
    async function submitFavorite() {
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        favorite_videos: [videoInfo.content.id],
      }, signedInUser.accesskey, apiURLs.updateFavorite)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403) {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      // setLoading(false);
      if (!submissionFailure) {
        let user = signedInUser;
        user.favorite_videos = submissionStatus.favorite_videos;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
      }
    }
    // setLoading(true);
    submitFavorite();
  };

  function handleTingledClick() {
    async function submitTingled() {
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        gave_tingles: [1],
      }, signedInUser.accesskey, `${apiURLs.updateTingles}${videoInfo.content.id}/`)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403) {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      // setLoading(false);
      if (!submissionFailure) {
        let info = JSON.parse(JSON.stringify(videoInfo))
        info.content.tingled = submissionStatus.gave_tingles.includes(signedInUser.pk);
        setVideoInfo(info);
      }
    }
    // setLoading(true);
    submitTingled();
  };


  if (videoInfo.error.hasError) {
    return (
      <Error type={videoInfo.error.type} statusCode={videoInfo.error.statusCode} description={videoInfo.error.description} />
    )
  }

  if (videoInfo.loading) {
    return (
      <Loading />
    )
  }

  let favorite_icon;
  if (signedInUser.accesskey && signedInUser.email_verified) {
    if (signedInUser.favorite_videos.includes(videoInfo.content.id)) {
      favorite_icon = <Tooltip title="Favorite"><StarFilled className="video-card-favorited" onClick={handleFavoriteClick} /></Tooltip>
    } else {
      favorite_icon = <Tooltip title="Favorite"><StarOutlined onClick={handleFavoriteClick} /></Tooltip>
    }
  } else {
    favorite_icon = <Tooltip title="Favorite"><StarOutlined className="video-inactive-icon" onClick={handleInactiveClick} /></Tooltip>
  }

  let tingles_icon;
  if (signedInUser.accesskey && signedInUser.email_verified) {
    if (videoInfo.content.tingled) {
      tingles_icon = <Tooltip title="Tingles!"><TinglesIconFilled className="video-card-tingled" onClick={handleTingledClick} /></Tooltip>
    } else {
      tingles_icon= <Tooltip title="Tingles!"><TinglesIcon onClick={handleTingledClick} /></Tooltip>
    }
  } else {
    tingles_icon = <Tooltip title="Tingles!"><TinglesIcon className="video-inactive-icon" onClick={handleInactiveClick} /></Tooltip>
  }

  let tag_icon;
  if (signedInUser.accesskey && signedInUser.email_verified) {
    tag_icon = <Tooltip title="Tags"><TagOutlined onClick={handleTagsClick} /></Tooltip>
  } else {
    tag_icon = <Tooltip title="Tags"><TagOutlined className="video-inactive-icon" onClick={handleInactiveClick}  /></Tooltip>
  }

  let report_icon;
  if (signedInUser.accesskey && signedInUser.email_verified) {
    report_icon = <Tooltip title="Report"><ExclamationCircleOutlined onClick={handleReportClick} /></Tooltip>
  } else {
    report_icon = <Tooltip title="Report"><ExclamationCircleOutlined className="video-inactive-icon" onClick={handleInactiveClick} /></Tooltip>
  }

  return (
    <>
      <Helmet>
        <title>{videoInfo.content.title ? `${videoInfo.content.title} | ASMRVIDEODB` : 'ASMR Video | ASMRVIDEODB'}</title>
        <meta name="description" content={`ASMR Video: Watch ${videoInfo.content.title} by ${videoInfo.content.artist_name}. Description: ${videoInfo.content.description.replace(/\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/g, '').substring(0,150)}...`} />
        <link rel="canonical" href={`https://www.asmrvideodb.com/video/${slugify(videoInfo.content.title)}/${video_id}`} />
      </Helmet>
      <TagManager
        key={videoInfo.content.tags + videoInfo.content.id}
        visible={tagModalOpen} 
        toggle={handleTagsClick} 
        tags={videoInfo.content.tags} 
        videoID={videoInfo.content.id}
        type='video'
        info={{videoInfo: videoInfo, setVideoInfo: setVideoInfo}}
      />
      <ReportManager
        visible={reportModalOpen} 
        toggle={handleReportClick} 
        objectID={videoInfo.content.id}
        modalTitle='Report This Video'
        type='video'
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={18} >
          <h3 className="shadowed-text video-main-title">{videoInfo.content.title}</h3>
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }, { xs: 0 }]}>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={18} >
          <div className='player-wrapper'>
            <div className={videoOverlayOpen ? 'react-player-overlay visible' : 'react-player-overlay'}>
              <Tooltip title="Replay"><ReplayIcon className='utilities-twoem utilities-hover-color' onClick={resetVideo} /></Tooltip>
            </div>
            <ReactPlayer 
              url={videoInfo.content.videoLink}
              ref={videoPlayer}
              controls={true} 
              playing={videoPlaying}
              className='react-player'
              width='100%'
              height='100%'
              onEnded={setVideoOverlay}
              onPlay={unsetVideoOverlay}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }, { xs: 2 }]}>
        <Col xs={13} sm={14} md={14} lg={9} xl={8} xxl={7} >
          <div className='video-artist-container'>
            <Avatar src={videoInfo.content.artist_avatar} onClick={handleArtistClick} />
            <span className='video-artist-name' onClick={handleArtistClick}>{videoInfo.content.artist_name}</span>
          </div>
        </Col>
        <Col xs={{span: 11}} sm={{span: 10}} md={{span: 10}} lg={{span: 7, offset: 6}} xl={{span: 6, offset: 6}} xxl={{span: 5, offset: 7}} >
          <div className='video-icons-container'>
            {favorite_icon}
            {tingles_icon}
            {tag_icon}
            {report_icon}
          </div>
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 10, md: 12}, { xs: 2 }]}>
        <Tabs className="utilities-white w-100 mb-4" defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <TagFilled />
                Tags
              </span>
            }
            key="1"
          >
            <TagList tags={videoInfo.content.tags} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ContainerFilled />
                Description
              </span>
            }
            key="2"
          >
            <Row gutter={[{ xs: 8, sm: 10, md: 12}, { xs: 2 }]}>
              <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={18} >
                {fullDescription ? 
                  <p className="white">{videoInfo.content.description}</p>
                  :
                  <TextTruncate
                    line={6}
                    element="span"
                    truncateText="…"
                    text={videoInfo.content.description}
                    textTruncateChild={<button className='utilities-link-button' onClick={toggleFullDescription}> view full</button>}
                  />
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <MessageFilled />
                Comments
              </span>
            }
            key="3"
          >
            <CommentList videoID={video_id} />
          </TabPane>
        </Tabs>
      </Row>
      <h1 className="userFavorites-heading">Similar Videos</h1>
      <div className="utilities-divider"> <span></span></div>
      <LazyLoad height={500} offset={100} once >
        <SimilarVideoList videoID={video_id} />
      </LazyLoad>
    </>
  )
}

export default Video;