import React, { useState, useContext } from 'react';
import Draggable from 'react-draggable';
import { Input, Select, Modal, Form, Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { userContext, authKeyContext } from '../App';
import { openMessage } from '../utilities/messages';
import { POSTWithKey } from '../utilities/apiCalls';
import apiURLs from '../apiURLs';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 21, offset: 3 },
    sm: { span: 7, offset: 0 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 21, offset: 3 },
    sm: { span: 8, offset: 0},
    md: { span: 8 },
  },
};

function ReportManager(props) {
  /* props: 
  objectID - ID of the video being reported,
  modalTitle - title of modal,
  visible - Is managager modal visible,
  toggle - toggle manager visibility,
  type - type of report manager,
  ?mask - should the modal have a mask.
  */ 
 let {objectID, modalTitle, visible, toggle, type, mask} = props;
  

  const [loading, setLoading] = useState(false);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const draggleRef = React.createRef();
  

  function onStart(event, uiData) {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }

  const onSubmit = values => {
    async function submitVideoReport() {
      let updateFailure = false
      await POSTWithKey({
        app_name: 'api',
        model_name: 'Video',
        model_id: objectID,
        reason: values.reason,
        info: values.info,
       }, signedInUser.accesskey, apiURLs.setFlag)
      .catch(error => {
        updateFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again to continue.');
          } else {
            openMessage('error', error.detail ? error.detail : error[0], 3);
          }
        }
      })
      setLoading(false);
      if (!updateFailure) {
        openMessage('success', 'Your report has been received. Thanks!');
      }
    }

    async function submitCommentReport() {
      let updateFailure = false
      await POSTWithKey({
        reason: values.reason,
        info: values.info,
       }, signedInUser.accesskey, `${apiURLs.getComments}${objectID}/flag/`)
      .catch(error => {
        updateFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again to continue.');
          } else {
            openMessage('error', error.detail ? error.detail : error[0], 3);
          }
        }
      })
      setLoading(false);
      if (!updateFailure) {
        openMessage('success', 'Your report has been received. Thanks!');
      }
    }
    
    setLoading(true);
    if (type === 'video') {
      submitVideoReport();
    } else if (type === 'comment') {
      submitCommentReport();
    }
  };

  let reportOptions;
  if (type === 'video') { 
    reportOptions = 
    <Select style={{ width: 300 }}>
      <Option value="1">This is not an ASMR Video</Option>
      <Option value="2">This video is private</Option>
      <Option value="3">This video is age restricted</Option>
      <Option value="100">Other (please explain below)</Option>
    </Select>
  }
  if (type === 'comment') {
    reportOptions =
    <Select style={{ width: 300 }}>
      <Option value="1">This comment is spam</Option>
      <Option value="2">This comment is abusive</Option>
      <Option value="100">Other (please explain below)</Option>
    </Select>
  }

  return( 
    <Modal
    title={
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
      >
        {modalTitle ? modalTitle : 'Update Tags'}
      </div>
      }
      mask={mask ? mask : false}
      visible={visible}
      onCancel={toggle}
      width={600}
      footer={[
        <Button key="back" onClick={toggle}>
          Cancel
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={form.submit}>
          Submit
        </Button>,
      ]}
      modalRender={modal => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="ReportForm"
        onFinish={onSubmit}
      >
      <Form.Item
        name="reason"
        label="Reason"
      >
        {reportOptions}
      </Form.Item>

        <Form.Item
          name="info"
          label={
          <span>
            Info&nbsp;
            <Tooltip title='Only necessary if you select "Other"'>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
          dependencies={['reason']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('reason') === '100' && (!value || value.length < 4)) {
                  return Promise.reject(new Error('Please provide a reason for your report.'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
        <Input style={{ width: 300 }}/>
      </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReportManager;