import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { 
  StarOutlined,
  FireOutlined,
  FireFilled,
  } from '@ant-design/icons';
import { TinglesIcon } from '../CustomIcons';
import { useMediaQuery } from 'react-responsive';
import VideoList from '../VideoList';

const { TabPane } = Tabs;

function TopRated() {

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)'
  })
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Helmet>
        <title>Most Popular ASMR Videos | ASMRVIDEODB</title>
        <meta name="description" content="A list of the most popular ASMR videos. Explore trending, most popular, or most favorited ASMR videos." />
        <link rel="canonical" href="https://www.asmrvideodb.com/popular-videos" />
      </Helmet>
      <h4 className='shadowed-text mb-0'><FireFilled /> POPULAR VIDEOS</h4>
      <Tabs className="utilities-white w-100" size={isLargeScreen ? 'large' : 'small'} defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <FireOutlined />
                Most Popular New Videos
              </span>
            }
            key="1"
          >
            <VideoList pagination={true} filterBlocked={true} displayBlockedVideosCounter={true} type='popular' />
          </TabPane>
          <TabPane
            tab={
              <span>
                <StarOutlined />
                Most Favorited All Time
              </span>
            }
            key="2"
          >
            <VideoList pagination={true} filterBlocked={true} displayBlockedVideosCounter={true} type='most_favorited' />
          </TabPane>
          <TabPane
            tab={
              <span>
                <TinglesIcon />
                Most Tingles All Time
              </span>
            }
            key="3"
          >
            <VideoList pagination={true} filterBlocked={true} displayBlockedVideosCounter={true} type='most_tingled' />
          </TabPane>
        </Tabs>
    </>
  )
}

export default TopRated;