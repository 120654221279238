import Icon from '@ant-design/icons';
import {ReactComponent as tinglesEmoji} from '../images/tingles-emoji.svg';
import {ReactComponent as tinglesEmojiFilled} from '../images/tingles-emoji-filled.svg';
import {ReactComponent as replayIcon} from '../images/replay-icon.svg';

const TinglesIcon = props => <Icon component={tinglesEmoji} {...props} />
const TinglesIconFilled = props => <Icon component={tinglesEmojiFilled} {...props} />
const ReplayIcon = props => <Icon component={replayIcon} {...props} />

export {TinglesIcon, TinglesIconFilled, ReplayIcon};

