import React from 'react';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';

function LoginRequiredAlert() {

  let alertDescription = <>If you don't have an account yet consider <span><Link to="/register" >signing up!</Link></span></>

  return (
    <Alert
      className='utilities-generic-error'
      message="You must be logged in to view this page."
      description={alertDescription}
      type="warning"
      showIcon
    />
  )
}

export default LoginRequiredAlert