import React from "react";
import { Spin } from 'antd';

function Loading() {

  return( 
    <Spin size="large" tip="Loading..." className="loading-center" />
  )
}

export default Loading;