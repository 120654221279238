import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from "react-router-dom";
import LoginRequiredAlert from "./LoginRequiredAlert.js";

function RouteWrapper(
  {
    component: Component, 
    layout: Layout,
    loggedIn,
    loginRequired,
    ...rest 
  }
) {
  if (loginRequired && !loggedIn) {
    return (
      <Route {...rest} render={(props) =>
        <Layout {...props}>
          <Helmet>
            <title>Login to View Page - ASMRVIDEODB</title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <LoginRequiredAlert />
        </Layout>
      } />
    );
  } else {
    return (
      <Route {...rest} render={(props) =>
        <Layout {...props}>
          <Component {...rest} /> 
        </Layout>
      } />
    );
  }
}

export default RouteWrapper;