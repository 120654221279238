import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate'; 
import { 
  Card,
  Avatar,
  Tooltip,
  Popover,
  Menu,
  Dropdown
  } from 'antd';
import { 
  StarOutlined,
  StarFilled,
  TagOutlined,
  EllipsisOutlined
  } from '@ant-design/icons';
import { userContext, authKeyContext } from '../App';
import TagList from './TagList';
import { PUTWithKey} from '../utilities/apiCalls';
import apiURLs from '../apiURLs';
import { openMessage } from '../utilities/messages';
import slugify from '../utilities/slugify';
import { setUserInfo, getUserInfo } from '../utilities/authentication';

const { Meta } = Card;

function VideoCard(props) {
  /* props: 
  id - DB ID of video,
  artistID - DB ID of artist,
  artistName - Name of artist,
  coverImage - Video cover image,
  artistImage - Artist avatar image,
  tags - Video Tags,
  isFavorited - if video is favorited by user,
  isTingled - if video has given tingles to user,
  title - Title of video,
  description - Description of video
  type - type of list,
  manage - info and setters,
  manageReportModel - manage video reporting modal,
  videoLink - Link to video
  */ 
  let {id, artistID, artistName, coverImage, artistImage, tags, isFavorited, isTingled, title, description, type, manage, manageReportModel, videoLink} = props;

  let {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const history = useHistory();

  function checkLoggedInAndEmailVerified(func) {
    if (signedInUser.accesskey) {
      if (signedInUser.email_verified) {
        func();
      } else {
        openMessage('warning', 'You must verify your email address before using this feature.', 3);
      }
    } else {
      openMessage('warning', 'You must be logged in to use this feature.', 3);
    }
  }

  function handleCoverClick() {
    history.push({
      pathname: `/video/${slugify(title)}/${id}`,
      state: {
        video: {
          id: id,
          title: title,
          description: description,
          artist: artistID,
          artist_name: artistName,
          artist_avatar: artistImage,
          tags: tags,
          tingled: isTingled,
          videoLink: videoLink
        }
      }
    })
  }

  function handleFavoriteClick() {
    async function submitFavorite() {
      let submissionFailure = false;
      let submissionStatus = await PUTWithKey({
        favorite_videos: [id],
      }, signedInUser.accesskey, apiURLs.updateFavorite)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.favorite_videos = submissionStatus.favorite_videos
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo())
        if (type === 'favorite') {
          if (!signedInUser.favorite_videos.includes(id)) {
            let updatedFavoriteVideoInfo = JSON.parse(JSON.stringify(manage.videos));
            updatedFavoriteVideoInfo.content = updatedFavoriteVideoInfo.content.filter(el => {return el.id !== id});
            manage.setVideos(updatedFavoriteVideoInfo);
          }
        }
      }
    }

    checkLoggedInAndEmailVerified(submitFavorite);
  };

  function handleMoreMenuClick({key}) {

    async function dontRecommend() {
      let submissionFailure = false;
      let submissionStatus = await PUTWithKey({
        blocked_videos: [id],
      }, signedInUser.accesskey, apiURLs.updateBlocked)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.blocked_videos = submissionStatus.blocked_videos;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
      }
    }

    async function submitArtistBlock() {
      if (signedInUser.favorite_artists.includes(artistID)) {
        openMessage('warning', `Please unfavorite ${artistName} before blocking!`, 3);
        return;
      }
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        blocked_artists: [artistID],
      }, signedInUser.accesskey, apiURLs.updateBlockArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.blocked_artists = submissionStatus.blocked_artists;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
        openMessage('success', `${artistName} has been blocked.`, 4);
      }
    }

    async function submitArtistFavorite() {
      if (signedInUser.favorite_artists.includes(artistID)) {
        openMessage('success', `${artistName} has already been favorited!`, 3);
        return;
      }
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        favorite_artists: [artistID],
      }, signedInUser.accesskey, apiURLs.updateFavoriteArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.favorite_artists = submissionStatus.favorite_artists;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
        openMessage('success', `${artistName} has been favorited.`, 4);
      }
    }

    function openReportModel() {
      manageReportModel.setSelectedVideo(id);
      manageReportModel.setReportModalOpen(true);
    }

    switch(key) {
      case 'not interested':
        checkLoggedInAndEmailVerified(dontRecommend);
        break;
      case 'report':
        checkLoggedInAndEmailVerified(openReportModel);
        break;
      case 'favoriteArtist':
        checkLoggedInAndEmailVerified(submitArtistFavorite);
        break;
      case 'blockArtist':
        checkLoggedInAndEmailVerified(submitArtistBlock);
        break;
      default:
        throw new Error('More Menu Key Not Found Among Available Options.');
    }
  }

  const moreMenu = (
    <Menu theme="dark" onClick={handleMoreMenuClick}>
      <Menu.Item key="not interested">
          Not Interested
      </Menu.Item>
      <Menu.Item key="favoriteArtist">
          Favorite Artist
      </Menu.Item>
      <Menu.Item key="blockArtist">
          Block Artist
      </Menu.Item>
      <Menu.Item key="report">
          Report
      </Menu.Item>
    </Menu>
  );

  const tagContent = (
    <div className='video-card-tag-popover'>
      <TagList tags={tags} />
    </div>
  )

  let favoriteOption = isFavorited ? 
    <Tooltip title="Unfavorite"><StarFilled className="video-card-favorited" onClick={handleFavoriteClick} key="favorited" /></Tooltip>
    :
    <Tooltip title="Favorite"><StarOutlined onClick={handleFavoriteClick} key="favorited" /></Tooltip>

  let cardActions = [
    favoriteOption, 
    <Popover content={tagContent} title="Tags"><TagOutlined key="tags" /></Popover>,
    <Dropdown overlay={moreMenu} placement="topRight" arrow><EllipsisOutlined key="more" /></Dropdown>
  ]

  return(
    <Card
      hoverable={true}
      className='video-card-main'
      cover={
        <img
          alt={`Video thumbnail for ${title}`}
          src={coverImage}
          onClick={handleCoverClick}
        />
      }
      actions={cardActions}
    >
      <Meta
        className='truncate-overflow'
        avatar={<Link to={`/artist/${slugify(artistName)}/${artistID}`}><Avatar src={artistImage} alt={artistName} /></Link>}
        title={ <TextTruncate
                  line={2}
                  element="a"
                  truncateText="…"
                  text={title} 
                  onClick={handleCoverClick}
                  style={{color: 'black'}}
                  href={`/video/${slugify(title)}/${id}`}
                />
                  }
        description={<Link to={`/artist/${slugify(artistName)}/${artistID}`}>{artistName}</Link>}
      />
    </Card>
  )
}

export default VideoCard;