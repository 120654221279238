import {message} from 'antd';

const openMessage = (messageType, messageContent, duration=6) => {
  switch (messageType) {
    case 'basic':
      message.open({ content: messageContent, duration: duration });
      break;
    case 'error':
      message.error({ content: messageContent, duration: duration });
      break;
    case 'success':
      message.success({ content: messageContent, duration: duration });
      break;
    case 'info':
      message.info({ content: messageContent, duration: duration });
      break;
    case 'warning':
      message.warning({ content: messageContent, duration: duration });
      break;
    case 'loading':
      message.loading({ content: messageContent, duration: duration });
      break;
    default:
      message.error({ content: 'An unknown error occured.', duration: duration });
  }
}

export {openMessage}