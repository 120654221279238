import React, { useState, useContext} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Alert,
  Divider
} from 'antd';
import { userContext, authKeyContext } from '../../App';
import { requestUpdateUser, setUserInfo, getUserInfo } from '../../utilities/authentication';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import { POSTWithKey } from '../../utilities/apiCalls';
import Loading from '../Loading';
import apiURLs from '../../apiURLs';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function UserProfile() {

  const [form] = Form.useForm();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  let {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);


  const onFinish = values => {
    async function updateUser() {
      let updateFailure = false
      let updatedUserInformation = await requestUpdateUser(signedInUser.accesskey, {
        username: values.username,
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender,
        age: values.age,
       }, apiURLs.updateUser)
        .catch(error => {
          updateFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              form.setFields(handleServerErrors(error));
            }
          }
        })
        setLoadingUpdate(false);
      if (!updateFailure) {
        setUserInfo(updatedUserInformation, signedInUser.accesskey)
        setSignedInUser(getUserInfo()); 
        openMessage('success', 'Your profile information has been successfully updated.');
      }
    }
    
    setLoadingUpdate(true);
    updateUser();
  };

  function resendValidationEmail() {
      async function requestValidationEmail() {
        let submissionFailure = false
        let submissionStatus = await POSTWithKey({email_varified: false}, signedInUser.accesskey, apiURLs.resendEmailConfirmation)
          .catch(error => {
            submissionFailure = true;
            if (error instanceof Error) {
              openMessage('error', error.message);
            } else {
              if (error.status === 403) {
                localStorage.removeItem('accessKey');
                localStorage.removeItem('user');
                setSignedInUser({username: 'Guest', loaded: true});
                setAuthenticationKey(false);
                openMessage('warning', 'You have been logged out, please sign in again to continue.');
              } else {
                openMessage('error', error.detail ? error.detail : error[0]);
              }
            }
          })
        // setLoading(false);
        if (!submissionFailure) {
          openMessage('success', submissionStatus.message, 4);
        }
      }
      // setLoading(true);
      requestValidationEmail();
    };

  if (!signedInUser.loaded  || !signedInUser.username) {
    return (
      <Loading />
    )
  }

  let alertDescription = <>You must verify your email address before using many of the features on this website. <span className='utilities-link-button blue' onClick={resendValidationEmail}>Click Here</span> to resend verification email.</>

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Profile | ASMRVIDEODB</title>
        <meta name="description" content="Manage your profile settings." />
        <meta name="robots" content="noindex" />
      </Helmet>
      {!signedInUser.email_verified &&
        <Alert
          message="Your email address has not been verified."
          showIcon
          description={alertDescription}
          type="warning"
          className="mb-4"
        />
      }
      <h3 className='shadowed-text'>
        Update Profile
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Link to="/change-password" className='form-link'>Change Password</Link>
      <Form
      {...formItemLayout}
      form={form}
      name="update-user"
      onFinish={onFinish}
      initialValues={{ username: signedInUser.username,
                       first_name:  signedInUser.first_name,
                       last_name: signedInUser.last_name,
                       age: signedInUser.age,
                       gender: signedInUser.gender,
                       }}
      className="mt-3"
      scrollToFirstError
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
        >
          <Select style={{ width: 120 }}>
            <Option value=""></Option>
            <Option value="M">Male</Option>
            <Option value="F">Female</Option>
            <Option value="NB">Non Binary</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="age"
          label="Age"
        >
          <InputNumber min={0} max={150} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingUpdate}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UserProfile