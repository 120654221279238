import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Form,
  Input,
  Button,
  Select,
  Divider
} from 'antd';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import { POSTWithKey, POSTAnon } from '../../utilities/apiCalls';
import Loading from '../Loading';
import apiURLs from '../../apiURLs';
import { userContext, authKeyContext } from '../../App';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


function ContactUs() { 

  const [form] = Form.useForm();
  const [loadingContact, setLoadingContact] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const onFinish = values => {
    async function sendContact() {
      let contactFailure = false;
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = POSTWithKey({
          email: values.email,
          category: values.category,
          name: values.name,
          message: values.message,
         }, signedInUser.accesskey, apiURLs.contact);
      } else {
        fetchRequest = POSTAnon({
          email: values.email,
          category: values.category,
          name: values.name,
          message: values.message,
         }, apiURLs.contact);
      }
      await fetchRequest
        .catch(error => {
          contactFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              form.setFields(handleServerErrors(error));
            }
          }
        })
        setLoadingContact(false);
      if (!contactFailure) {
        openMessage('success', 'Your message has been sent!', 4);
        form.resetFields();
      }
    }

    if (signedInUser.loaded) {
      setLoadingContact(true);
      sendContact();
    }
  };

  if (!signedInUser.loaded || !signedInUser.username) {
    return (
      <Loading />
    )
  }

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Contact | ASMRVIDEODB</title>
        <meta name="description" content="We'd love to hear from you! Ask a question or give us feedback!" />
        <link rel="canonical" href="https://www.asmrvideodb.com/contact-us" />
      </Helmet>
      <h3 className='shadowed-text'>
        Contact Us
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
        {...formItemLayout}
        form={form}
        name="contact"
        onFinish={onFinish}
        className="mt-3"
        scrollToFirstError
        initialValues={{ 
                        email: signedInUser.accesskey ? signedInUser.email : '',
                        name:  signedInUser.accesskey && signedInUser.first_name ? `${signedInUser.first_name} ${signedInUser.last_name}` : '' ,
                       }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'This is not a valid E-mail address!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >        
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input your name.', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please choose a category.' }]}
        >
          <Select>
            <Option value="Question">Ask a Question</Option>
            <Option value="Problem">Describe a Problem</Option>
            <Option value="Bug">Report a Bug</Option>
            <Option value="Business Inquiry">Business Inquiry</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item 
          name="message"
          label="Message"
          rules={[{ required: true, message: 'Please include a message.', whitespace: true}]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingContact}>
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ContactUs;