import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Table,
  } from 'antd';
import { userContext, authKeyContext} from '../../App';
import { GETAnon, GETWithKey } from '../../utilities/apiCalls';
import apiURLs from '../../apiURLs';
import { openMessage } from '../../utilities/messages';
import Error from '../Error';
import Loading from '../Loading';


function TopTaggers() {

  const [taggersInfo, setTaggersInfo] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: false
  });
  const [userTagPoints, setUserTagPoints] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  useEffect(() => {
    async function getTaggersInfo() {
      let getTaggerInfoFailure = false;
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = GETWithKey(signedInUser.accesskey, apiURLs.listTopTaggers);
      } else {
        fetchRequest = GETAnon(apiURLs.listTopTaggers);
      }
      let retrievedTaggerInfo = await fetchRequest
      .catch(error => {
        getTaggerInfoFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
          } else {
            setTaggersInfo({
              loading: false,
              error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
              content: false
            });
          }
        }
      })

      window.scrollTo(0, 0);
      if (!getTaggerInfoFailure) {
        setUserTagPoints(retrievedTaggerInfo.pop()['user_tag_points']);
        for (let [i, v] of retrievedTaggerInfo.entries()) {
          v.rank = i + 1
          v.key = i
        }
        setTaggersInfo({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: retrievedTaggerInfo
        })
      }
    }
    
    if (signedInUser.loaded) {
      getTaggersInfo();
    }
  }, [signedInUser.loaded, signedInUser.accesskey, setAuthenticationKey, setSignedInUser])


  if (taggersInfo.error.hasError) {
    return (
      <Error type={taggersInfo.error.type} statusCode={taggersInfo.error.statusCode} description={taggersInfo.error.description} />
    )
  }

  if (taggersInfo.loading) { 
    return (
      <Loading />
    )
  }

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Tag Points',
      dataIndex: 'tag_points',
      key: 'tag_points',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Top Taggers | ASMRVIDEODB</title>
        <meta name="description" content="A list of the users who helped tag the most ASMR videos on ASMRVIDEODB." />
        <link rel="canonical" href="https://www.asmrvideodb.com/top-taggers" />
      </Helmet>
      <div className="topTaggers-table-container">
        <h3 className="topTaggers-title shadowed-text">Top Taggers</h3>
        <h4 className="topTaggers-subtitle ">Users who have helped contribute by tagging the most videos</h4>
        <br />
        {userTagPoints ? <h4 className="topTaggers-subtitle">Your Tag Points: {userTagPoints}</h4> : null }
        <Table
          className='topTaggers-table-striped-rows'
          size="small"
          dataSource={taggersInfo.content}
          columns={columns}
          bordered
          pagination={ {pageSize: 100}}
        />
      </div>
    </>
  )
}

export default TopTaggers;