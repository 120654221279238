import React, { useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { getKeyOrExpire, requestUserInfo, setUserInfo, getUserInfo } from './utilities/authentication';
import RouteWrapper from './components/RouteWrapper';
import MainDisplay from './components/MainDisplay';
import { openMessage } from './utilities/messages';
import { UserFormLayout, ContentListLayout, HomeLayout } from './layouts';
import apiURLs from './apiURLs';
import './css/App.min.css';
// Pages
import Home from './components/pages/Home';
import ArtistOverview from './components/pages/ArtistOverview';
import Video from './components/pages/Video';
import FourOFour from './components/pages/FourOFour';
import EmailVerified from './components/pages/EmailVerified';
import EmailVerificationFailure from './components/pages/EmailVerificationFailure';
import UserRegister from './components/pages/UserRegister';
import PasswordChange from './components/pages/PasswordChange';
import PasswordReset from './components/pages/PasswordReset';
import PasswordResetConfirm from './components/pages/PasswordResetConfirm';
import UserProfile from './components/pages/UserProfile';
import ContactUs from './components/pages/ContactUs';
import UserManageTriggers from './components/pages/UserManageTriggers';
import UserFavorites from './components/pages/UserFavorites';
import SearchResults from './components/pages/SearchResults';
import TopRated from './components/pages/TopRated';
import TopTaggers from './components/pages/TopTaggers';
import ArtistSubmit from './components/pages/ArtistSubmit';
import About from './components/pages/About';
import Donate from './components/pages/Donate';
import SubmittedArtistModeration from './components/pages/SubmittedArtistModeration';

export const userContext = React.createContext({username: 'Guest', loaded: false});
export const authKeyContext = React.createContext(false);

function App() {
  
  const [authenticationKey, setAuthenticationKey] = useState(false);
  const [signedInUser, setSignedInUser] = useState({username: 'Guest', loaded: false});
  const [searchLoading, setSearchLoading] = useState(false);
  const manageAuthentication = {'authenticationKey': authenticationKey, 'setAuthenticationKey': setAuthenticationKey}
  const manageUser = {'signedInUser': signedInUser, 'setSignedInUser': setSignedInUser}
  const manageSearchLoading = {'searchLoading': searchLoading, 'setSearchLoading': setSearchLoading}

  useEffect(() => {
    let accessKey = false;
    try {
      accessKey = getKeyOrExpire('accessKey');
    } catch (e) {
      accessKey = false;
    }
    if (accessKey) {
      setAuthenticationKey(accessKey);
    } else {
      localStorage.removeItem('user');
      setSignedInUser({username: 'Guest', loaded: true});
    }
  }, [])

  useEffect(() => {
    async function getUserInfoOnStart() {
      let getUserInfoFailure = false;
      let userInfo = await requestUserInfo(authenticationKey, apiURLs.getUser)
      .catch(error => {
        getUserInfoFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          openMessage('error', error.statusText);
        }
      })
      if (!getUserInfoFailure) {
        setUserInfo(userInfo, authenticationKey);
        setSignedInUser(getUserInfo());
      }
    }

    let user;
    if (authenticationKey) {
      try {
        user = getUserInfo();
        setSignedInUser(user);
      } catch (e) {
        getUserInfoOnStart();
      }
      if (user.accesskey !== authenticationKey) {
        getUserInfoOnStart();
      }
    }
  }, [authenticationKey])

  return (
    <userContext.Provider value={manageUser}>
      <authKeyContext.Provider value={manageAuthentication}>
        <Helmet>
          <title>ASMRVIDEODB</title>
          <meta name="description" content="Find and track your favorite ASMR artists and videos. Personalized recommendations based on your favorite triggers. Filter out videos based on triggers you don't like." />
        </Helmet>
        <MainDisplay authentication={manageAuthentication} searchLoading={manageSearchLoading}> 
          <Switch>
            <RouteWrapper path="/register" component={UserRegister} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} setAuthKey={setAuthenticationKey} redirect={authenticationKey} />
            <RouteWrapper path="/profile" component={UserProfile} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/change-password" component={PasswordChange} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/reset-password" component={PasswordReset} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/password-reset/confirm/:uid/:token/" component={PasswordResetConfirm} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/email-verified" component={EmailVerified} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/email-verification-failure" component={EmailVerificationFailure} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/contact-us" component={ContactUs} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/manage-triggers" component={UserManageTriggers} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/favorites" component={UserFavorites} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/search/:term" component={SearchResults} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} searchLoading={manageSearchLoading} />
            <RouteWrapper path="/popular-videos" component={TopRated} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/top-taggers" component={TopTaggers} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/submit-artist" component={ArtistSubmit} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/about" component={About} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/donate" component={Donate} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/moderate-submitted-artists" component={SubmittedArtistModeration} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={true} />
            <RouteWrapper path="/artist/:artist_name/:artist_id" component={ArtistOverview} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="/video/:video_title/:video_id" component={Video} layout={ContentListLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper exact path="/" component={Home} layout={HomeLayout} loggedIn={authenticationKey} loginRequired={false} />
            <RouteWrapper path="*" component={FourOFour} layout={UserFormLayout} loggedIn={authenticationKey} loginRequired={false} />
          </Switch>
        </MainDisplay>
      </authKeyContext.Provider>
    </userContext.Provider>
  );
}
export default App;
