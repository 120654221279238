import React from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';

function EmailVerificationFailure() {

  return (
    <>
      <Helmet>
        <title>Your Email Could Not Be Verified</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Alert
        message="There was a problem validating your email address."
        showIcon
        description="Please contact us if this problem persists."
        type="error"
      />
    </>
  )
}

export default EmailVerificationFailure;