import {handleAPIErrors as handleErrors} from "./handleAPIErrors" 

async function POSTWithKey(info, key, url, timeout=9000) {
	let controller = new AbortController();
  let timeoutController = setTimeout(() => controller.abort(), timeout);
  let authHeader = 'Token ' + key
  let submissionSuccess = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authHeader,
    },
    body: JSON.stringify(info),
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await submissionSuccess;
}

async function POSTAnon(info, url, timeout=9000) {
	let controller = new AbortController();
  let timeoutController = setTimeout(() => controller.abort(), timeout);
  let submissionSuccess = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(info),
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await submissionSuccess;
}

async function GETWithKey(key, url, timeout=9000) {
	let controller = new AbortController();
  let timeoutController = setTimeout(() => controller.abort(), timeout);
  let authHeader = 'Token ' + key
  let getSuccess = await fetch(url, {
		method: 'GET',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authHeader,
		}
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await getSuccess;
}

async function GETAnon(url, timeout=9000) {
	let controller = new AbortController();
  let timeoutController = setTimeout(() => controller.abort(), timeout);
  let getSuccess = await fetch(url, {
		method: 'GET',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
		}
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await getSuccess;
}

async function PUTWithKey(info, key, url, timeout=9000) {
	let controller = new AbortController();
  let timeoutController = setTimeout(() => controller.abort(), timeout);
  let authHeader = 'Token ' + key
  let submissionSuccess = await fetch(url, {
		method: 'PUT',
		mode: 'cors',
		signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authHeader,
    },
    body: JSON.stringify(info),
	})
	.then(handleErrors)
	.then(response => response.json())
	clearTimeout(timeoutController);
	return await submissionSuccess;
}

export {POSTWithKey, POSTAnon, GETWithKey, GETAnon, PUTWithKey}