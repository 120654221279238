import React, { useState, useContext, useRef, useEffect } from 'react';
import {Link, useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { requestLogout } from '../utilities/authentication';
import { openMessage } from '../utilities/messages';
import { 
  Layout,
  Menu,
  Dropdown,
  Button,
  Input,
  notification 
 } from 'antd';
import {
  IdcardOutlined,
  StarOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  FireOutlined,
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  ProfileOutlined,
  InfoCircleOutlined,
  MailOutlined,
  DashOutlined,
  TagOutlined,
  HeartOutlined
} from '@ant-design/icons';
import {userContext} from '../App';
import DropdownLogin from './DropdownLogin';
import apiURLs from '../apiURLs';

const { Content, Footer, Sider } = Layout;
const { Search } = Input;

function MainDisplay(props) {

  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const history = useHistory();
  const location = useLocation();
  const isMidScreen = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const searchInput = useRef(null);

  useEffect(() => {
    if (signedInUser.accesskey && !signedInUser.email_verified) {
      notification.info(
        {
          message: 'Email Verification',
          description: 'You must verify your email before using many of the features on this website. If you have not yet received a verification email please check your spam inbox. A verification email can be re-sent via a link on your profile page.',
          duration: 16,
        }
      )
    }
  }, [signedInUser.accesskey, signedInUser.email_verified])


  function onCollapse() {
    if (sideMenuCollapsed) {
      if (isMidScreen) {
        setSideMenuCollapsed(!sideMenuCollapsed);
      }
    } else {
      setSideMenuCollapsed(!sideMenuCollapsed)
    }
  };

  function truncateString(str, num) {
    if (!str) {
      return ''
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  function handleLoggedInMenuClick({key}) {

    async function logout() {
      let logoutFailure = false;
      let logoutStatus = await requestLogout(props.authentication.authenticationKey, apiURLs.logout)
      .catch(error => {
        logoutFailure = true
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403) {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            props.authentication.setAuthenticationKey(false);
          } else {
            openMessage('error', error.statusText);
          }
        }
      })
      if (!logoutFailure) {
        if (logoutStatus.detail === 'Successfully logged out.') {
          localStorage.removeItem('accessKey');
          localStorage.removeItem('user');
          setSignedInUser({username: 'Guest', loaded: true});
          props.authentication.setAuthenticationKey(false);
        } else {
          throw new Error(`Logout Failure: ${logoutStatus.detail}`);
        }
      }
    }
    switch(key) {
      case 'profile':
        history.push("/profile");
        break;
      case 'triggers':
        history.push("/manage-triggers");
        break;
      case 'logout':
        logout();
        break;
      default:
        throw new Error('Log In Menu Key Not Found Among Available Options.');
    }
  }

  function directToRegisterPage() {
    history.push("/register");
  }

  function directToHomePage() {
    history.push("/");
  }

  function handleMainSearch(index, event) {
    let currentPath= location.pathname.replace("/search/","");
    if (index && index !== currentPath) {
      props.searchLoading.setSearchLoading(true);
      history.push(`/search/${index}`); 
      searchInput.current.setValue('');
    }
  }

  const dropdownLogin = (
    <DropdownLogin authentication={props.authentication} />
  );

  const dropdownLoggedIn = (
    <Menu theme="dark" onClick={handleLoggedInMenuClick}>
      <Menu.Item key="profile" icon={<ProfileOutlined />}>
        Profile
      </Menu.Item>
      <Menu.Item key="triggers" icon={<UserOutlined />}>
        Manage Triggers
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Log Out
      </Menu.Item>
    </Menu>
  )

  // Alter top nav for logged in users vs logged out.

  let topMenu;
  if (!props.authentication.authenticationKey) {
    topMenu =
      <Menu theme="dark" mode="horizontal" className="nav-header-fixed" overflowedIndicator={<DashOutlined />} >
        <Menu.Item key="logo" selectable="false" className="nav-noSelect nav-logo" >
          <p onClick={directToHomePage}>ASMRVIDEO<span className='mini-db'>DB</span></p>
        </Menu.Item>
        <Menu.Item key="search-bar" selectable="false" className="nav-noSelect nav-topnav-search">
          <Search placeholder="Search..." ref={searchInput} size={isMidScreen ? "middle" : "small"} onSearch={handleMainSearch} loading={props.searchLoading.searchLoading} enterButton />
        </Menu.Item>
        <Menu.Item key="dropdown-login-menu" selectable="false" className="nav-topnav-item-right nav-noSelect nav-dropdown-login" >
          <Dropdown overlay={dropdownLogin} trigger="click" placement="bottomRight" arrow>
            <Button size={isMidScreen ? "large" : "small"} type="primary">LOG IN</Button>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="register-menu" selectable="false" className="nav-topnav-item-right nav-noSelect" onClick={directToRegisterPage} >
          <Button size={isMidScreen ? "large" : "small"}>REGISTER</Button>
        </Menu.Item>
      </Menu>
    } else {
      topMenu = 
        <Menu theme="dark" mode="horizontal" className="nav-header-fixed" overflowedIndicator={<DashOutlined />} >
          <Menu.Item key="logo" selectable="false" className="nav-noSelect nav-logo" >
            <p onClick={directToHomePage}>ASMRVIDEO<span className='mini-db'>DB</span></p>
          </Menu.Item>
          <Menu.Item key="search-bar" selectable="false" className="nav-noSelect nav-topnav-search">
            <Search placeholder="Search..." ref={searchInput} size={isMidScreen ? "middle" : "small"} onSearch={handleMainSearch} loading={props.searchLoading.searchLoading} enterButton />
          </Menu.Item>
          <Menu.Item key="dropdown-logged-in-menu" selectable="false" className="nav-topnav-item-right nav-noSelect" >
            <Dropdown overlay={dropdownLoggedIn} trigger="click" placement="bottomRight" arrow>
              {isMidScreen ? 
                <Button className='nav-logged-in-button' type="ghost" size="large" shape="round" icon={<UserOutlined />}>{truncateString(signedInUser.username, 17)}<DownOutlined /></Button>
                :
                <Button className='nav-centered-user-icon' size='large' type="ghost" shape="circle" ><UserOutlined /></Button>
              }
            </Dropdown>
          </Menu.Item>
        </Menu>
      }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={sideMenuCollapsed} onCollapse={onCollapse} breakpoint={'md'} className="sider-fixed">
        <Menu theme="dark" selectedKeys={[location.pathname]} mode="inline">
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="/popular-videos" icon={<FireOutlined />}>
            <Link to="/popular-videos">Popular Videos</Link>
          </Menu.Item>
          <Menu.Item key="/favorites" icon={<StarOutlined />}>
            <Link to="/favorites">Favorites</Link>
          </Menu.Item>
          <Menu.Item key="/submit-artist" icon={<PlusCircleOutlined />}>
            <Link to="/submit-artist">Submit Artist</Link>
          </Menu.Item>
          <Menu.Item key="/top-taggers" icon={<TagOutlined />}>
            <Link to="/top-taggers">Top Taggers</Link>
          </Menu.Item>
          <Menu.Item key="/about" icon={<InfoCircleOutlined />}>
            <Link to="/about">About</Link>
          </Menu.Item>
          <Menu.Item key="/donate" icon={<HeartOutlined />}>
            <Link to="/donate">Donate</Link>
          </Menu.Item>
          <Menu.Item key="/contact-us" icon={<MailOutlined />}>
            <Link to="/contact-us">Contact</Link>
          </Menu.Item>
          {signedInUser.is_moderator &&
            <Menu.Item key="/moderate-submitted-artists" icon={<IdcardOutlined />}>
              <Link to="/moderate-submitted-artists">Artist Submissions</Link>
            </Menu.Item>
          }
        </Menu>
      </Sider>
      <Layout>
        {topMenu}
        <Content className={sideMenuCollapsed ? 'sider-collapsed' : 'sider-full'}>
          {props.children}
        </Content>
        <Footer className='footer'>
          <div className='footer-content'>
            <p>Copyright &copy; 2021{moment().year() !== 2021 ? `-${moment().year()}` : null} ASMRVIDEODB</p>
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default MainDisplay;