import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {useParams} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import {
  Row,
  Col,
  Image,
  Statistic,
  Tabs,
  Skeleton,
  Tooltip
  } from 'antd';
import { 
  ThunderboltOutlined,
  StarOutlined,
  StarFilled,
  CloseSquareOutlined,
  CloseSquareFilled,
  YoutubeOutlined
  } from '@ant-design/icons';
import { TinglesIcon } from '../CustomIcons';
import { userContext} from '../../App';
import { GETAnon, GETWithKey, PUTWithKey } from '../../utilities/apiCalls';
import { setUserInfo, getUserInfo } from '../../utilities/authentication';
import VideoList from '../VideoList';
import apiURLs from '../../apiURLs';
import { openMessage } from '../../utilities/messages';
import Error from '../Error';

const { TabPane } = Tabs;

function ArtistOverview() {

  const [artistInfo, setArtistInfo] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: false
  });
  const [artistVideos, setArtistVideos] = useState([])
  const { artist_id } = useParams();
  const {signedInUser, setSignedInUser} = useContext(userContext);

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)'
  })

  useEffect(() => {
    async function getArtistInfo() {
      let getArtistInfoFailure = false;
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = GETWithKey(signedInUser.accesskey, `${apiURLs.artistInfo}${artist_id}/`);
      } else {
        fetchRequest = GETAnon(`${apiURLs.artistInfo}${artist_id}`);
      }
      let retrievedArtistInfo = await fetchRequest
      .catch(error => {
        getArtistInfoFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            // localStorage.removeItem('accessKey');
            // localStorage.removeItem('user');
            // setSignedInUser({username: 'Guest', loaded: true});
            // setAuthenticationKey(false);
            // Unnecessary because video list api call will do this.
          } else {
            setArtistInfo({
              loading: false,
              error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
              content: false
            });
          }
        }
      })
      if (!getArtistInfoFailure) {
        setArtistInfo({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: retrievedArtistInfo
        })
      }
    }
    
    window.scrollTo(0,0);
    if (signedInUser.loaded) {
      getArtistInfo();
    }
  }, [artist_id, signedInUser.loaded, signedInUser.accesskey])

  function handleInactiveClick() {
    openMessage('warning', 'You must be logged in to use this feature.', 3);
  }

  function handleFavoriteClick() {
    async function submitArtistFavorite() {
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        favorite_artists: [artist_id],
      }, signedInUser.accesskey, apiURLs.updateFavoriteArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            openMessage('error', error.detail ? error.detail : error[0]);
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.favorite_artists = submissionStatus.favorite_artists;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
      }
    }

    if (signedInUser.blocked_artists.includes(parseInt(artist_id))) {
      openMessage('error', 'You need to unblock this artist before you can add them to your favorites.');
      return;
    }
    submitArtistFavorite();
  };

  function handleBlockClick() {
    async function submitArtistBlock() {
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        blocked_artists: [artist_id],
      }, signedInUser.accesskey, apiURLs.updateBlockArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            openMessage('error', error.detail ? error.detail : error[0]);
          }
        })
      if (!submissionFailure) {
        let user = signedInUser;
        user.blocked_artists = submissionStatus.blocked_artists;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
      }
    }

    if (signedInUser.favorite_artists.includes(parseInt(artist_id))) {
      openMessage('error', 'You need to unfavorite this artist before you can block them.');
      return;
    }
    submitArtistBlock();
  };

  if (artistInfo.error.hasError) {
    return (
      <Error type={artistInfo.error.type} statusCode={artistInfo.error.statusCode} description={artistInfo.error.description} />
    )
  }

  let favorite_icon;
  if (signedInUser.accesskey) {
    if (signedInUser.favorite_artists.includes(parseInt(artist_id))) {
      favorite_icon = <Tooltip title="Favorite"><StarFilled className="artist-overview-favorite-icon favorited" onClick={handleFavoriteClick} /></Tooltip>
    } else {
      favorite_icon = <Tooltip title="Favorite"><StarOutlined className="artist-overview-favorite-icon" onClick={handleFavoriteClick} /></Tooltip>
    }
  } else {
    favorite_icon = <Tooltip title="Favorite"><StarOutlined className="artist-overview-favorite-icon inactive" onClick={handleInactiveClick} /></Tooltip>
  }

  let block_icon;
  if (signedInUser.accesskey) {
    if (signedInUser.blocked_artists.includes(parseInt(artist_id))) {
      block_icon = <Tooltip title="Block"><CloseSquareFilled className="artist-overview-blocked-icon blocked" onClick={handleBlockClick} /></Tooltip>
    } else {
      block_icon = <Tooltip title="Block"><CloseSquareOutlined className="artist-overview-blocked-icon" onClick={handleBlockClick} /></Tooltip>
    }
  } else {
    block_icon = <Tooltip title="Block"><CloseSquareOutlined className="artist-overview-blocked-icon inactive" onClick={handleInactiveClick} /></Tooltip>
  }

  let artistArea;
  if (artistInfo.loading) {
    artistArea =
      <div className="mt-4">
        <Row gutter={[{ xs: 16, sm: 24, md: 36 }, { xs: 16, sm: 24, md: 36 }]} style={{paddingLeft: 18}} >
          <Skeleton.Input style={{ width: 200 }} active={true} size='large' />
        </Row>
        <Row gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 4, sm: 8, md: 12 }]}>
          <Col xs={24} sm={24} md={24} lg={14} xl={10} xxl={7} >
            <Skeleton.Avatar shape='square' size='large' active={true} style={{width: 350, height: 350}}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={9} xxl={8} >
            <Skeleton active={true}/>
          </Col>
        </Row>
      </div>
  } else {
    artistArea =
    <div>
      <Row gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 4, sm: 8, md: 12 }]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={10} xxl={7} >
          <h2 className="mb-1 shadowed-text">{artistInfo.content.name}</h2>
          <a 
            href={`https://www.youtube.com/channel/${artistInfo.content.youtube_id}`} 
            target="_blank" rel='noreferrer' className='artist-overview-channel-label'><YoutubeOutlined />  Youtube
          </a>
          {favorite_icon}{block_icon}
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }, { xs: 4, sm: 8, md: 12, lg: 24, xl: 24 }]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={10} xxl={7} >
          <Image
            className='artist-overview-artist-image'
            preview={false}
            src={artistInfo.content.thumbnail_high}
            alt={`${artistInfo.content.name}'s Youtube avatar.`}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={13} xxl={16} >
          <h3 className='shadowed-text'>Description:</h3>
          <p>
            {artistInfo.content.description ? artistInfo.content.description : "This artist has not provided a channel description."}
          </p>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} md={6} lg={4} xl={3} xxl={2} >
          <Statistic className="artist-overview-stat" title="Subscribers" value={artistInfo.content.subscriber_count} />
        </Col>
        <Col xs={24} sm={8} md={6} lg={4} xl={3} xxl={2} >
          <Statistic className="artist-overview-stat" title="Videos" value={artistInfo.content.video_count} />
        </Col>
        <Col xs={24} sm={8} md={6} lg={4} xl={3} xxl={2} >
          <Statistic className="artist-overview-stat" title="Views" value={artistInfo.content.view_count} />
        </Col>
      </Row>
    </div>
  }

  return (
    <>
      <Helmet>
        <title>{`${artistInfo.content.name} | ASMRVIDEODB`}</title>
        <meta name="description" content={`${artistInfo.content.name}'s ASMRVIDEODB profile page. ASMR videos by ${artistInfo.content.name}.`} />
      </Helmet>
      {artistArea}
      <Row>
        <h4 className='shadowed-text mb-0'>Videos:</h4>
      </Row>
      <Row>
        <Tabs className="utilities-white w-100" size={isLargeScreen ? 'large' : 'small'} defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <ThunderboltOutlined />
                Most Recent
              </span>
            }
            key="1"
          >
            <VideoList pagination={true} type='artist' artist={artist_id} retrieveVideos={setArtistVideos} sortType='date' />
          </TabPane>
          <TabPane
            tab={
              <span>
                <StarOutlined />
                Most Favorited
              </span>
            }
            key="2"
          >
            <VideoList pagination={true} type='artist' artist={artist_id} providedVideos={artistVideos} sortType='favorites' />
          </TabPane>
          <TabPane
            tab={
              <span>
                <TinglesIcon />
                Most Tingles
              </span>
            }
            key="3"
          >
            <VideoList pagination={true} type='artist' artist={artist_id} providedVideos={artistVideos} sortType='tingles' />
          </TabPane>
        </Tabs>
      </Row>
    </>
  )
}

export default ArtistOverview;