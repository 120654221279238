async function handleAPIErrors(response) {
	if (!response.ok) {
		let status = response.status
		let statusText = response.statusText
		let json = await response.json()
		const error = Object.assign({}, json, {
			status: status,
			statusText: statusText,
		});
		return Promise.reject(error);
	}
	return response;
}

function handleServerErrors(error) {
	let serverErrors = [];
	for (let value in error) {
		if (value !== 'status' && value !== 'statusText') {
			serverErrors.push({name: value, errors: error[value]});
		}
	}
	return serverErrors
}

export {handleAPIErrors, handleServerErrors};