import React, { useState, useContext } from 'react';
import Draggable from 'react-draggable';
import { Tag, Select, Modal, Form, Button } from 'antd';
import { tagList, tagColors } from '../utilities/tagList';
import { userContext, authKeyContext } from '../App';
import { openMessage } from '../utilities/messages';
import { PUTWithKey } from '../utilities/apiCalls';
import apiURLs from '../apiURLs';
import { setUserInfo, getUserInfo } from '../utilities/authentication';

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  return (
    <Tag color={tagColors[value.replaceAll(" ", "_")]} closable={closable} onClose={onClose} style={{ marginRight: 4 }}>
      {label}
    </Tag>
  );
}

function TagManager(props) {
  /* props: 
  ?videoID - ID of the video whose tags are being edited,
  modalTitle - title of modal,
  visible - Is managager modal visible,
  toggle - toggle manager visibility,
  tags - list of current video tags,
  type - type of tag manager,
  ?info - tag list source information and setter
  */ 
 let {videoID, modalTitle, visible, toggle, tags, type, info} = props;
  

  const [loading, setLoading] = useState(false);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const draggleRef = React.createRef();
  
  const options = [];
  for (const optionGroup in tagList) {
    let groupTags = [];
    for (const tag of tagList[optionGroup]) {
      groupTags.push(
        {label: tag, value: tag}
      )
    }
    options.push({
      label: optionGroup,
      options: groupTags
    })
  }

  function onStart(event, uiData) {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }

  const onSubmit = values => {
    async function updateTags() {
      let updateFailure = false
      let fetchRequest;
      if (type === 'favorite') {
        if (values.tags.some(tag => signedInUser.blocked_tags.includes(tag))) {
          openMessage('error', 'You can not favorite a trigger that is in your blocked triggers list.');
          setLoading(false);
          return
        }
        fetchRequest = PUTWithKey({
          favorite_tags: values.tags,
        }, signedInUser.accesskey, `${apiURLs.UpdateUserTriggers}?${new URLSearchParams({type: 'favorite'})}`);
      } else if (type === 'blocked') {
        if (values.tags.some(tag => signedInUser.favorite_tags.includes(tag))) {
          openMessage('error', 'You can not block a trigger that is in your favorite triggers list.');
          setLoading(false);
          return
        }
        fetchRequest = PUTWithKey({
          blocked_tags: values.tags,
        }, signedInUser.accesskey, `${apiURLs.UpdateUserTriggers}?${new URLSearchParams({type: 'blocked'})}`);
      } else { // defaults to video
        fetchRequest = PUTWithKey({
          tags: values.tags,
        }, signedInUser.accesskey, `${apiURLs.updateTags}${videoID}/`);
      }
      let updatedTags = await fetchRequest
      .catch(error => {
        updateFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again to continue.');
          } else {
            openMessage('error', error.detail ? error.detail : error[0]);
          }
        }
      })
      setLoading(false);
      if (!updateFailure) {
        if (type === 'video') {
          openMessage('success', 'Video tags have been updated successfully!');
          let updatedVideoInfo = JSON.parse(JSON.stringify(info.videoInfo));
          updatedVideoInfo.content.tags = updatedTags.tags;
          info.setVideoInfo(updatedVideoInfo);
        } else if (type === 'favorite') {
            openMessage('success', 'Your favorite triggers have been updated successfully!');
            let user = signedInUser;
            user.favorite_tags = updatedTags.favorite_tags;
            setUserInfo(user, signedInUser.accesskey);
            setSignedInUser(getUserInfo());
        } else if (type === 'blocked') {
            openMessage('success', 'Your blocked triggers have been updated successfully!');
            let user = signedInUser;
            user.blocked_tags = updatedTags.blocked_tags;
            setUserInfo(user, signedInUser.accesskey);
            setSignedInUser(getUserInfo());
        }
      }
    }
    if (JSON.stringify(tags) === JSON.stringify(values.tags)) {
      openMessage('warning', 'Please update tags before submitting.');
      return;
    }
    setLoading(true);
    updateTags();
  };

  return( 
    <Modal
    title={
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
      >
        {modalTitle ? modalTitle : 'Update Tags'}
      </div>
      }
      mask={false}
      visible={visible}
      onCancel={toggle}
      width={600}
      footer={[
        <Button key="back" onClick={toggle}>
          Cancel
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={form.submit}>
          Submit
        </Button>,
      ]}
      modalRender={modal => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
    >
      <Form
        form={form}
        name="UpdateTagForm"
        onFinish={onSubmit}
      >
        <Form.Item
          name="tags"
          initialValue={tags}
        >
          <Select
            placeholder="Select Video Tags"
            mode="multiple"
            size="large"
            showArrow
            tagRender={tagRender}
            options={options}
          />
        </Form.Item>  
      </Form>
    </Modal>
  )
}

export default TagManager;