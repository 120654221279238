import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Form,
  Input,
  Button,
  Divider
} from 'antd';
import { userContext, authKeyContext } from '../../App';
import { POSTWithKey} from '../../utilities/apiCalls';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import apiURLs from '../../apiURLs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function PasswordChange() { 

  const [form] = Form.useForm();
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  let {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  const onFinish = values => {
    async function changePassword() {
      let passwordChangeFailure = false
      await POSTWithKey({
        new_password1: values.new_password1,
        new_password2: values.new_password2,
        old_password: values.old_password,
       }, signedInUser.accesskey, apiURLs.passwordChange)
        .catch(error => {
          passwordChangeFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again to continue.');
            } else {
              form.setFields(handleServerErrors(error));
            }
          }
        })
        setLoadingChangePassword(false);
      if (!passwordChangeFailure) {
        openMessage('success', `Your password has been updated!`);
        form.resetFields();
      }
    }
    
    setLoadingChangePassword(true);
    changePassword();
  };


  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Change Password | SHHASMRDB</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h3 className='shadowed-text'>
        Change Password
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
        {...formItemLayout}
        form={form}
        name="password change"
        onFinish={onFinish}
        className="mt-3"
        scrollToFirstError
      >

      <Form.Item
        name="new_password1"
        label="New Password"
        rules={[
          {
            required: true,
            message: 'Please input your new password.',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="new_password2"
        label="Confirm New Password"
        dependencies={['new_password1']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your new password.',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password1') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="old_password"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please input your old password.',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingChangePassword}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PasswordChange;