import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row, Pagination } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import VideoCard from './VideoCard';
import ReportManager from './ReportManager';
import { GETAnon, GETWithKey } from '../utilities/apiCalls';
import { userContext, authKeyContext } from '../App';
import apiURLs from '../apiURLs';
import { openMessage } from '../utilities/messages';
import Error from './Error';
import Loading from './Loading';

function VideoList(props) {
  /* props: 
  ?pagination - should the list be paginated (default false),
  ?itemsPerPage - items per page (default 20),
  type - type of list, used in api request (options are artist, favorite, recent, popular, most_tingles, most_favorited),
  ?artist - artist id, used in artist api request,
  ?filterBlocked - filter artist and video based on user preferences
  ?term - search term
  ?filterTerm - filter term for list
  ?sortType - list sorted by (options are tingles, favorites, date),
  ?providedVideos - optional list of videos to use instead of sending api request
  ?retrieveVideos - used to optionally push video list to parent
  ?retrievalStatus - used to optionally get status of retrieved videos API call
  ?clickForMore - click to increase page size.
  ?setBlockedVideos - set number of blocked videos, state setter passed from parent.
  ?displayBlockedVideosCounter - display number of blocked videos at bottom of list.
  */ 
  const {pagination, itemsPerPage, type, artist, filterBlocked, term, filterTerm, sortType, providedVideos, retrieveVideos, retrievalStatus, clickForMore, setBlockedVideos, displayBlockedVideosCounter} = props;

  const [videos, setVideos] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: []
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(itemsPerPage ? itemsPerPage : 20);
  const [moreVisable, setMoreVisable] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  const videoContainer = useRef(null);

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const originalPageSize = itemsPerPage ? itemsPerPage : 20;
  let blockedVideoCount = 0;


  useEffect(() => {
    async function getVideos(retry = false) {
      let getVideosFailure = false;
      let setRetrieval = true;
      let requestInfo = {
        type: type
      }
      if (artist) {
        requestInfo.artist = artist
      }
      if (term) {
        requestInfo.term = term
      }
      let fetchRequest;
      if (signedInUser.accesskey) {
        fetchRequest = GETWithKey(signedInUser.accesskey, `${apiURLs.listVideos}?${new URLSearchParams(requestInfo)}`, 12500);
      } else {
        fetchRequest = GETAnon(`${apiURLs.listVideos}?${new URLSearchParams(requestInfo)}`, 12500);
      }
      let videoList = await fetchRequest
      .catch(error => {
        getVideosFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again.');
          } else {
            if (!error.status && !retry) {
              setRetrieval = false;
              getVideos(true);
            } else {
              setVideos({
                loading: false,
                error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
                content: []
              });
            }
          }
        }
      })
      if (!getVideosFailure) {
        setVideos({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: videoList
        });
        if (retrieveVideos) {
          retrieveVideos(videoList);
        }
      }
      if (retrievalStatus && setRetrieval) {
        retrievalStatus(true);
      }
    }

    if (signedInUser.loaded) {
      if (!providedVideos) {
        getVideos();
      } else {
        setVideos({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: providedVideos
        });
      }
    }
  }, [providedVideos, retrieveVideos, retrievalStatus, type, artist, term, signedInUser.loaded, signedInUser.accesskey, setAuthenticationKey, setSignedInUser])

  useEffect(() => {
    if (setBlockedVideos && blockedVideoCount) {
      setBlockedVideos(blockedVideoCount);
    }
  })

  function handlePageChange(page, pageSize) {
    setPage(page - 1);
    window.scrollTo(0, videoContainer.current.offsetTop - 180);
  }

  function increasePageSize() {
    if (perPage + originalPageSize < sortedVideos.length) {
      setPerPage(perPage + originalPageSize);
    } else {
      setMoreVisable(false);
      setPerPage(sortedVideos.length);
    }
  }

  function handleReportClick() {
    setReportModalOpen(!reportModalOpen);
  }

  function filterVideoList(video) {
    let blockedTag, blockedVideo, blockedArtist;
    if (signedInUser.blocked_tags.length) {
      blockedTag = signedInUser.blocked_tags.some(tag => video.tags.includes(tag));
    }
    if (signedInUser.blocked_videos.length) {
      blockedVideo = signedInUser.blocked_videos.includes(video.id);
    }
    if (signedInUser.blocked_artists.length) {
      blockedArtist = signedInUser.blocked_artists.includes(video.artist);
    }
    return !blockedTag && !blockedVideo && !blockedArtist;
  }  


  if (videos.error.hasError) {
    return (
      <Error type={videos.error.type} statusCode={videos.error.statusCode} description={videos.error.description} />
    )
  }

  if (videos.loading) {
    return (
      <Loading />
    )
  }

  let sortedVideos = Array.from(videos.content);
  if (filterBlocked && signedInUser.accesskey) {
    if (signedInUser.blocked_tags.length || signedInUser.blocked_videos.length || signedInUser.blocked_artists.length) {
      sortedVideos = sortedVideos.filter(filterVideoList);
      blockedVideoCount = videos.content.length - sortedVideos.length
    }
  }
  if (filterTerm) {
    let termList = filterTerm.toLowerCase().split(" ");
    sortedVideos = sortedVideos.filter(video => {
      let artist_name = video.artist_name.toLowerCase();
      let title = video.title.toLowerCase();
      for (let word of termList) {
        if (artist_name.includes(word) || title.includes(word) || video.tags.some(tag => tag.toLowerCase().includes(word))) {
          return true;
        }
      }
      return false;
    })
  }
  if (sortType) {
    if (sortType === 'favorites') {
      sortedVideos = sortedVideos.sort((a, b) => b.total_favorites - a.total_favorites);
    } else if (sortType === 'tingles') {
      sortedVideos = sortedVideos.sort((a, b) => b.total_tingles - a.total_tingles);
    } else {
      sortedVideos = sortedVideos.sort((a, b) => Date.parse(b.date_uploaded) - Date.parse(a.date_uploaded));
    }
  }

  const videosList = sortedVideos.slice(page * perPage, (page * perPage) + perPage).map(video => 
    <Col xs={24} sm={20} md={12} lg={12} xl={8} xxl={6} key={video.id + '-' + video.video_id}>
      <VideoCard
        key={video.id}
        id={video.id}
        artistID={video.artist}
        artistName={video.artist_name}
        title={video.title}
        description={video.description}
        coverImage={video.thumbnail_high}
        artistImage={video.artist_avatar}
        tags={video.tags}
        videoLink={`https://www.youtube.com/watch?v=${video.video_id}`}
        isTingled={video.tingled}
        type = {type}
        manage={{videos: videos, setVideos: setVideos}}
        manageReportModel={{setReportModalOpen: setReportModalOpen, setSelectedVideo: setSelectedVideo}}
        isFavorited={signedInUser.favorite_videos ? signedInUser.favorite_videos.includes(video.id) : false}
      />
    </Col>
  );

  return(
    <div className='mb-3'>
      <ReportManager
        visible={reportModalOpen} 
        toggle={handleReportClick} 
        objectID={selectedVideo}
        mask = {true}
        modalTitle='Report This Video'
        type='video'
      />
      <Row justify={isLargeScreen ? 'start' : 'center'} ref={videoContainer} className={videosList.length > 0 ? '' : 'pl-2'} gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 24, sm: 22, md: 20 }]}>
        {videosList.length > 0 ? videosList : 'There are no videos to display.'}
      </Row>
      { clickForMore &&
        <Row justify='end' className={moreVisable ? '' : 'utilities-hide'} gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 12, sm: 16, md: 24 }]}>
          <button className='button-view-more mr-4' onClick={increasePageSize}>View More{<CaretRightOutlined className='caret-right' />}</button>
        </Row>
      }
      { displayBlockedVideosCounter && blockedVideoCount > 0 &&
        <p className='utilities-filtered-videos-text-display mt-3'>{blockedVideoCount} videos filtered</p>
      }
      { pagination &&
        <Row justify='center' gutter={[{ xs: 12, sm: 16, md: 24 }, { xs: 12, sm: 16, md: 24 }]}>
          <Pagination
              className='my-4 mx-auto'
              total={videos.content.length}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              pageSize={20}
              hideOnSinglePage={true}
              showQuickJumper={false}
              showSizeChanger={false}
              onChange={handlePageChange}
              defaultCurrent={1}
          />
        </Row>
      }
    </div>
  )
}

export default VideoList;