import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {useHistory} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import LazyLoad from 'react-lazyload';
import { Button, Col, Row } from 'antd';
import { userContext } from '../../App';
import VideoList from '../VideoList';
import Delay from '../Delay';
import Loading from '../Loading';
import nightHairMeditator from '../../images/woman-meditating-with-night-hair.svg';
import womanHeadphones from '../../images/woman-with-red-headphones.svg';

function Home() {

  const [filteredRecentVideoCount, setFilteredRecentVideoCount] = useState(0);
  const [filteredTrendingVideoCount, setFilteredTrendingVideoCount] = useState(0);
  const [filteredRecommendedVideoCount, setFilteredRecommendedVideoCount] = useState(0);
  const [filteredFavoritesVideoCount, setFilteredFavoritesVideoCount] = useState(0);
  const {signedInUser} = useContext(userContext);
  const history = useHistory();

  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)'
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  function handleClick() {
    if (signedInUser.accesskey) {
      document.getElementById('home-main').scrollIntoView({behavior: "smooth"});
    } else {
      history.push('/register');
    }
  }

  const videoLists = 
    signedInUser.loaded ?
    <>
      {signedInUser.accesskey ?
        <>
          <h4 className='mt-4 '>Recommended {filteredRecommendedVideoCount > 0 && <span className='utilities-filtered-videos-text'>{`${filteredRecommendedVideoCount} videos filtered`}</span>}</h4>
          <div className="utilities-divider"> <span></span></div>
          <VideoList type='recommended' filterBlocked={true} itemsPerPage={8} clickForMore={true} setBlockedVideos={setFilteredRecommendedVideoCount} />
        </>
        :
        <>
          <h4 className='mt-4 '>Trending {filteredTrendingVideoCount > 0 && <span className='utilities-filtered-videos-text'>{`${filteredTrendingVideoCount} videos filtered`}</span>}</h4>
          <div className="utilities-divider"> <span></span></div>
          <VideoList type='popular' filterBlocked={true} itemsPerPage={8} clickForMore={true} setBlockedVideos={setFilteredTrendingVideoCount} />
        </>
      }
      {signedInUser.accesskey && signedInUser.favorite_artists.length >= 3 && signedInUser.favorite_tags.length >= 2 ?
        <>
          <h4 className='mt-4 '>New From Your Favorites {filteredFavoritesVideoCount > 0 && <span className='utilities-filtered-videos-text'>{`${filteredFavoritesVideoCount} videos filtered`}</span>}</h4>
          <div className="utilities-divider"> <span></span></div>
          <Delay timeout={850}>
            <LazyLoad height={500} offset={275} once >
              <VideoList type='new_from_favorite_artists' filterBlocked={true} itemsPerPage={8} clickForMore={true} setBlockedVideos={setFilteredFavoritesVideoCount} />
            </LazyLoad>
          </Delay>
        </>
        :
        null
      }
      <h4 className='mt-4 '>Newly Uploaded {filteredRecentVideoCount > 0 && <span className='utilities-filtered-videos-text'>{`${filteredRecentVideoCount} videos filtered`}</span>}</h4>
      <div className="utilities-divider"> <span></span></div>
      <Delay timeout={850}>
        <LazyLoad height={500} offset={275} once >
          <VideoList type='recent' filterBlocked={true} itemsPerPage={8} clickForMore={true} setBlockedVideos={setFilteredRecentVideoCount} />
        </LazyLoad>
      </Delay>
      </>
      :
      <Loading />

  return (
    <div className='home-body-container'>
      <Helmet>
        <title>ASMRVIDEODB</title>
        <meta name="description" content="Find and track your favorite ASMR artists and videos. Personalized recommendations based on your favorite triggers. Filter out videos based on triggers you don't like." />
        <link rel="canonical" href="https://www.asmrvideodb.com" />
      </Helmet>
      {isLargeScreen ? 
        <div className='home-hero-container'>
          <div className='home-hero-overlay'>
            <h1 className='shadowed-text'><span className='home-title-color'>ASMR</span><span>VIDEO</span><span className='home-title-db-color'>DB</span></h1>
            <p>Track your favorite videos and artists.</p>
            <Button size='large' onClick={handleClick} ghost>Get Started</Button>
          </div>
          <div className='svg-container'>
            <img className='svg-content' src={nightHairMeditator} alt='Woman meditating with stars in her hair.'/>
          </div>
        </div>
      :
        <div>
          <Row className='home-small-hero-overlay' align="middle">
              <Col xs={15}>
                <Row justify="center">
                    <h2 className='shadowed-text'><span className={'home-title-color'}>ASMR</span><span>VIDEO</span><span className='home-title-db-color'>DB</span></h2>
                </Row>
                <Row justify="center">
                    <p>Track your favorite videos and artists.</p>
                </Row>
                <Row justify="center">
                  <Button size='medium' onClick={handleClick} ghost>Get Started</Button>
                </Row>
              </Col>
            <Col xs={9} sm={8}>
              <div className='svg-container'>
                <img className='svg-content' src={womanHeadphones} alt='Woman wearing red headphones.'/>
              </div>
            </Col>
          </Row>
        </div>
      }
      <div id='home-main' className='home-content-container'>
        {videoLists}
      </div>
    </div>
  )
}

export default Home;