import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import { tagColors } from '../utilities/tagList';

function TagList(props) {
  const history = useHistory();
  const tags = props.tags ? props.tags : [];
  const videoTags = tags.map(tag => 
    <Tag key={tag} color={tagColors[tag.replaceAll(" ", "_")]} onClick={clickToSearch}>{tag}</Tag>
  );

  function clickToSearch(e) {
    history.push(`/search/${e.currentTarget.textContent}`); 
  }

  return( 
    <> 
      {tags.length > 0 ? videoTags : 'Nothing yet!'}
    </>
  )
}

export default TagList;