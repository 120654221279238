import React, { createElement, useState, useContext, useEffect } from 'react';
import { Comment, Tooltip, Avatar, Popover, Form, Input, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import TextTruncate from 'react-text-truncate'; 
import moment from 'moment';
import { DislikeOutlined, LikeOutlined, DislikeFilled, LikeFilled, FlagOutlined } from '@ant-design/icons';
import { userContext, authKeyContext } from '../App';
import { POSTWithKey, PUTWithKey } from '../utilities/apiCalls';
import { openMessage } from '../utilities/messages';
import apiURLs from '../apiURLs';
import avatarm1 from '../images/avatar-m1.png';
import avatarm2 from '../images/avatar-m2.png';
import avatarm3 from '../images/avatar-m3.png';
import avatarf1 from '../images/avatar-f1.png';
import avatarf2 from '../images/avatar-f2.png';
import avatarf3 from '../images/avatar-f3.png';

const { TextArea } = Input;

function GenericComment(props) {
  /* props: 
    author - comment author
    authorID - comment author ID
    commentID - comment id
    modelID - ID of model object being commented on
    avatar - number of user avatar option
    content - content of coment
    timePosted - time content was posted
    timeEdited - time comments was edited
    reactions - reaction object
    depth - depth of comment
    childrenComments - Comment replies array
    manageReportModel - manage comment reporting modal,
  */ 
  const {author, authorID, commentID, modelID, avatar, content, timePosted, timeEdited, reactions, depth, childrenComments, manageReportModel} = props;

  const [likes, setLikes] = useState(reactions.likes);
  const [dislikes, setDislikes] = useState(reactions.dislikes);
  const [action, setAction] = useState(null);
  const [fullComment, setFullComment] = useState(false);
  const [fullReplies, setFullReplies] = useState(false);
  const [replyOpen, setReplyOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [reactionLoading, setReactionLoading] = useState(false);
  const [commentContent, setCommentContent] = useState(content);
  const [childComments, setChildComments] = useState(childrenComments);
  const [formReply] = Form.useForm();
  const [formEdit] = Form.useForm();
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  useEffect(() => {
    if (reactions.users.likes.some(like => like.id === signedInUser.pk)) {
      setAction('liked');
    } else if (reactions.users.dislikes.some(dislike => dislike.id === signedInUser.pk)) {
      setAction('disliked');
    }
  }, [reactions.users.dislikes, reactions.users.likes, signedInUser.pk])


  function toggleReplyForm() {
    if (depth === 3) {
      openMessage('warning', 'Maximum reply depth reached.', 3);
      return;
    }
    setReplyOpen(!replyOpen);
  }

  function toggleEditForm() {
    setEditOpen(!editOpen);
  }

  function toggleFullComment() {
    setFullComment(!fullComment);
  }

  function toggleFullReplies() {
    setFullReplies(!fullReplies);
  }

  function openReportModel() {
    manageReportModel.setSelectedComment(commentID);
    manageReportModel.setReportModalOpen(true);
  }

  function like() {
    if (signedInUser.accesskey) {
      if (!signedInUser.email_verified) {
        openMessage('warning', 'You must verify your email address before using this feature.', 3);
        return;
      }
      if (signedInUser.pk === authorID) {
        openMessage('warning', 'You can not rate your own comments.', 3);
        return;
      }
    } else {
      openMessage('warning', 'You must be logged in to use this feature.', 3);
      return;
    }
    if (reactionLoading) {
      return;
    }
    if (action === 'liked') {
      setLikes(likes - 1);
      setAction(null);
    } else if (action === 'disliked') {
      setLikes(likes + 1);
      setDislikes(dislikes - 1);
      setAction('liked');
    } else {
      setLikes(likes + 1);
      setAction('liked');
    }
    handleReaction('like');
  };

  function dislike() {
    if (signedInUser.accesskey) {
      if (!signedInUser.email_verified) {
        openMessage('warning', 'You must verify your email address before using this feature.', 3);
        return;
      }
      if (signedInUser.pk === authorID) {
        openMessage('warning', 'You can not rate your own comments.', 3);
        return;
      }
    } else {
      openMessage('warning', 'You must be logged in to use this feature.', 3);
      return;
    }
    if (reactionLoading) {
      return;
    }
    if (action === 'disliked') {
      setDislikes(dislikes - 1);
      setAction(null);
    } else if (action === 'liked') {
      setDislikes(dislikes + 1);
      setLikes(likes - 1);
      setAction('disliked');
    } else {
      setDislikes(dislikes + 1);
      setAction('disliked');
    }
    handleReaction('dislike');
  };

  function handleReaction(reactionType) {
    async function submitReaction() {
      await POSTWithKey({}, signedInUser.accesskey, `${apiURLs.getComments}${commentID}/react/${reactionType}/`)
        .catch(error => {
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      setReactionLoading(false);
    }

    setReactionLoading(true);
    submitReaction();
  };

  function handleReplySubmit(values) {
    async function submitComment() {
      let submissionFailure = false;
      let requestInfo = {
        model_name: 'Video',
        model_id: modelID,
        app_name: 'api',
        parent_id: commentID
      }
      let submittedComment = await POSTWithKey({
        content: values.replyBox
       }, signedInUser.accesskey, `${apiURLs.postComment}?${new URLSearchParams(requestInfo)}`)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
        setReplyLoading(false);
      if (!submissionFailure) {
        submittedComment.reactions = {"likes":0,"dislikes":0,"users":{"likes":[],"dislikes":[]}}
        let updatedChildList = JSON.parse(JSON.stringify(childComments));
        updatedChildList.push(submittedComment);
        setChildComments(updatedChildList);
        setReplyOpen(false);
      }
    }
    
    setReplyLoading(true);
    submitComment();
  };

  function handleEditSubmit(values) {
    async function editComment() {
      let submissionFailure = false
      let editedComment = await PUTWithKey({
        content: values.editBox
       }, signedInUser.accesskey, `${apiURLs.getComments}${commentID}/`)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
        setEditLoading(false);
      if (!submissionFailure) {
        setCommentContent(editedComment.content);
        setEditOpen(false);
        // deal with time edited here
      }
    }
    
    setEditLoading(true);
    editComment();
  };

  function compareTime(postDate, editDate) {
    return moment(postDate).format('YYYY-MM-DD HH:mm') === moment(editDate).format('YYYY-MM-DD HH:mm');
  }

  let replies;
  if (childComments) {
    replies = fullReplies ? childComments : childComments.slice(0,2);
    replies = replies.map(item => 
      <GenericComment
        depth={depth + 1}
        key={item.id}
        commentID={item.id}
        modelID={modelID}
        avatar={item.user.avatar}
        author={item.user.username}
        authorID={item.user.id}
        content={item.content}
        timePosted={item.posted}
        timeEdited={item.edited}
        reactions={item.reactions}
        childrenComments={item.replies}
        manageReportModel={{setReportModalOpen: manageReportModel.setReportModalOpen, setSelectedComment: manageReportModel.setSelectedComment}}
      />
    )
  }


  const replyForm = 
    <Form
      name="replyForm"
      onFinish={handleReplySubmit}
      form={formReply}
      className='generic-comment-reply-field'
    >
      <Form.Item name="replyBox">
        <TextArea rows={3} placeholder="Write your reply..." />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={replyLoading} type="primary">
          Submit
        </Button>
        <Button className='ml-3' type="danger" onClick={toggleReplyForm}>
          Cancel
        </Button>
      </Form.Item>
    </Form>

  const editForm = 
    <Form
      name="editForm"
      onFinish={handleEditSubmit}
      form={formEdit}
      className='generic-comment-edit-popover'
    >
      <Form.Item name="editBox" initialValue={content} style={{marginBottom: '.4em'}}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item style={{marginBottom: '.3em'}}>
        <Button htmlType="submit" loading={editLoading} type="primary" style={{marginTop: '.4em'}}>
          Save
        </Button>
      </Form.Item>
    </Form>

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
        <span className="generic-comment-reaction-number">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
        <span className="generic-comment-reaction-number">{dislikes}</span>
      </span>
    </Tooltip>,
    signedInUser.accesskey &&
    <Tooltip key="comment-flag" title="Report" onClick={openReportModel}>
      <FlagOutlined />
    </Tooltip>,
    signedInUser.accesskey && signedInUser.email_verified && <span key="comment-basic-reply" onClick={toggleReplyForm}>Reply</span>,
    signedInUser.accesskey && signedInUser.pk === authorID &&
    <Popover placement="topLeft" trigger="click" visible={editOpen} onVisibleChange={toggleEditForm} content={editForm}><span key="comment-basic-edit">Edit</span></Popover> 
  ];

  let avatarImage;
  switch (avatar) {
    case 1:
      avatarImage = avatarm1;
      break;
    case 2:
      avatarImage = avatarm2;
      break;
    case 3:
      avatarImage = avatarm3;
      break;
    case 4:
      avatarImage = avatarf1;
      break;
    case 5:
      avatarImage = avatarf2;
      break;
    case 6:
      avatarImage = avatarf3;
      break;
    default:
      avatarImage = avatarm1;
  } 

  return (
    <Comment
      className='generic-comment-comment-box'
      actions={actions}
      author={author}
      avatar={
        <Avatar
          src={avatarImage}
          alt="Avatar"
        />
      }
      content={
        <>
          {fullComment ? 
            <p className="white">{commentContent}</p>
            :
            <TextTruncate
              line={5}
              element="span"
              truncateText="…"
              text={commentContent}
              textTruncateChild={<button className='utilities-link-button' onClick={toggleFullComment}> view full</button>}
            />
          }
        </>
      }
      datetime={
        <Tooltip title={moment(compareTime(timePosted, timeEdited) ? timePosted : timeEdited).format('YYYY-MM-DD HH:mm:ss')}>
        { compareTime(timePosted, timeEdited) ? 
          <span>{moment(timePosted, 'YYYY-MM-DDTHH:mm:ssZ').fromNow()}</span>
          :
          <span>Edited - {moment(timeEdited, 'YYYY-MM-DDTHH:mm:ssZ').fromNow()}</span>
        }
        </Tooltip>
      }
    >
      {replyOpen && replyForm}
      {replies && replies}
      {!fullReplies && childrenComments.length > 2 && 
        <button className='button-view-more ml-3' onClick={toggleFullReplies}>More Replies{<CaretRightOutlined className='caret-right' />}</button>
      }
    </Comment>
  );
};

export default GenericComment;