import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Form,
  Input,
  Button,
  Divider,
} from 'antd';
import { POSTAnon} from '../../utilities/apiCalls';
import { openMessage } from '../../utilities/messages';
import { handleServerErrors } from '../../utilities/handleAPIErrors';
import apiURLs from '../../apiURLs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12},
    md: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function PasswordReset() { 

  const [sendEmailForm] = Form.useForm();
  const [loadingResetEmail, setLoadingResetEmail] = useState(false);

  const onResetEmailFinish = values => {
    async function resetEmail() {
      let passwordResetFailure = false;
      await POSTAnon({
        email: values.email,
       }, apiURLs.passwordResetEmail)
        .catch(error => {
          passwordResetFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            sendEmailForm.setFields(handleServerErrors(error));
          }
        })
        setLoadingResetEmail(false);
      if (!passwordResetFailure) {
        openMessage('success', `An email explaining how to reset your password has been sent.`);
        sendEmailForm.resetFields();
      }
    }
    
    setLoadingResetEmail(true);
    resetEmail();
  };

  return (
    <div className="layout-form-container layout-user-form-background-card">
      <Helmet>
        <title>Reset Password | SHHASMRDB</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h3 className='shadowed-text'>
        Send Password Reset Email
      </h3>
      <Divider className='mt-1 mb-4'/>
      <Form
        {...formItemLayout}
        form={sendEmailForm}
        name="send reset email"
        onFinish={onResetEmailFinish}
        className="mt-3"
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'This is not a valid E-mail address!',
            },
            {
              required: true,
              message: 'Please input your E-mail address!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingResetEmail}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PasswordReset;