import { useState, useEffect } from 'react';
import Loading from './Loading';

function Delay(props) {

  const {timeout, children} = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, timeout)
  }, [show, timeout])

  if (!show) {
    return(
      <Loading />
    )
  }

  return(
    <>
      {children}
    </>
  )
}

export default Delay;