import React, { useContext } from 'react';
import { Card, Tooltip } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { 
  StarOutlined,
  StarFilled } from '@ant-design/icons';
import { userContext, authKeyContext } from '../App';
import { PUTWithKey} from '../utilities/apiCalls';
import apiURLs from '../apiURLs';
import { openMessage } from '../utilities/messages';
import slugify from '../utilities/slugify';
import { setUserInfo, getUserInfo } from '../utilities/authentication';

const { Meta } = Card;

function ArtistCard(props) {
   /* props: 
  artistID - DB ID of artist,
  artistName - Name of artist,
  artistImage - Artist avatar image,
  isFavorited - if artist is favorited by user,
  manage - Artist info and setter
  */ 
  const {artistID, artistName, artistImage, isFavorited, manage} = props

  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);
  const history = useHistory();

  function handleFavoriteClick() {
    async function submitArtistFavorite() {
      let submissionFailure = false
      let submissionStatus = await PUTWithKey({
        favorite_artists: [artistID],
      }, signedInUser.accesskey, apiURLs.updateFavoriteArtist)
        .catch(error => {
          submissionFailure = true;
          if (error instanceof Error) {
            openMessage('error', error.message);
          } else {
            if (error.status === 403 && error.detail === 'Invalid token.') {
              localStorage.removeItem('accessKey');
              localStorage.removeItem('user');
              setSignedInUser({username: 'Guest', loaded: true});
              setAuthenticationKey(false);
              openMessage('warning', 'You have been logged out, please sign in again.');
            } else {
              openMessage('error', error.detail ? error.detail : error[0]);
            }
          }
        })
      // setLoading(false);
      if (!submissionFailure) {
        let user = signedInUser;
        user.favorite_artists = submissionStatus.favorite_artists;
        setUserInfo(user, signedInUser.accesskey);
        setSignedInUser(getUserInfo());
        let updatedFavoriteArtistInfo = JSON.parse(JSON.stringify(manage.userFavoriteInfo));
        updatedFavoriteArtistInfo.content = updatedFavoriteArtistInfo.content.filter(el => {return el.id !== artistID});
        manage.setUserFavoriteInfo(updatedFavoriteArtistInfo);
      }
    }
    
    // setLoading(true);
    submitArtistFavorite();
  };

  function handleCoverClick() {
    history.push(`/artist/${slugify(artistName)}/${artistID}`);
  }

  let favoriteOption;
  if (isFavorited) {
    favoriteOption =  <Tooltip title="Unfavorite"><StarFilled className="video-card-favorited" onClick={handleFavoriteClick} key="favorited" /></Tooltip>
  } else {
    favoriteOption = <Tooltip title="Favorite"><StarOutlined onClick={handleFavoriteClick} key="favorited" /></Tooltip>
  }

  let cardActions = [
    favoriteOption
  ]

  return(
    <Card
      size='small'
      hoverable={true}
      className='video-card-artist'
      cover={
        <img
          alt={artistName}
          src={artistImage}
          onClick={handleCoverClick}
        />
      }
      actions={cardActions}
    >
      <Meta
        className='truncate-overflow'
        title={<Link to={`/artist/${slugify(artistName)}/${artistID}`}>{artistName}</Link>}
      />
    </Card>
  )

}

export default ArtistCard;