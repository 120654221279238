import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Input } from 'antd';
import { userContext, authKeyContext } from '../../App';
import apiURLs from '../../apiURLs';
import { GETWithKey } from '../../utilities/apiCalls';
import { openMessage } from '../../utilities/messages';
import ArtistCard from '../ArtistCard';
import VideoList from '../VideoList';
import Error from '../Error';
import Loading from '../Loading';

const { Search } = Input;

function UserFavorites() {

  const [userFavoriteInfo, setUserFavoriteInfo] = useState({
    loading: true,
    error: {hasError: false, type: '', statusCode: '', description: ''},
    content: false
  });
  const [listFilter, setListFilter] = useState(false);
  const {signedInUser, setSignedInUser} = useContext(userContext);
  const {setAuthenticationKey} = useContext(authKeyContext);

  useEffect(() => {
    async function getArtistInfo() {
      let getUserFavoritesFailure = false;
      let retrievedArtistInfo = await GETWithKey(signedInUser.accesskey, apiURLs.userFavoriteArtists)
      .catch(error => {
        getUserFavoritesFailure = true;
        if (error instanceof Error) {
          openMessage('error', error.message);
        } else {
          if (error.status === 403 && error.detail === 'Invalid token.') {
            localStorage.removeItem('accessKey');
            localStorage.removeItem('user');
            setSignedInUser({username: 'Guest', loaded: true});
            setAuthenticationKey(false);
            openMessage('warning', 'You have been logged out, please sign in again.');
          } else {
            setUserFavoriteInfo({
              loading: false,
              error: {hasError: true, type: error.statusText, statusCode: error.status, description: error.detail ? error.detail : error[0]},
              content: false
            });
          }
        }
      })

      window.scrollTo(0,0);
      if (!getUserFavoritesFailure) {
        setUserFavoriteInfo({
          loading: false,
          error: {hasError: false, type: '', statusCode: '', description: ''},
          content: retrievedArtistInfo
        })
      }
    }
    
    if (signedInUser.loaded && signedInUser.accesskey) {
      getArtistInfo();
    }
  }, [signedInUser.accesskey, signedInUser.loaded, setAuthenticationKey, setSignedInUser])


  function triggerListFilter(value) {
    setListFilter(value);
  }

  if (userFavoriteInfo.error.hasError) {
    return (
      <Error type={userFavoriteInfo.error.type} statusCode={userFavoriteInfo.error.statusCode} description={userFavoriteInfo.error.description} />
    )
  }

  if (userFavoriteInfo.loading) { // not ideal do better
    return (
      <Loading />
    )
  }

  const ArtistList = userFavoriteInfo.content.map(artist => 
    <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4} key={artist.id}>
      <ArtistCard
        key={artist.id}
        artistID={artist.id}
        artistName={artist.name}
        description={artist.description}
        artistImage={artist.thumbnail_high}
        manage={{userFavoriteInfo: userFavoriteInfo, setUserFavoriteInfo: setUserFavoriteInfo}}
        isFavorited={signedInUser.favorite_artists ? signedInUser.favorite_artists.includes(artist.id) : false}
      />
    </Col>
  );

  return (
    <>
      <Helmet>
        <title>Favorites | ASMRVIDEODB</title>
        <meta name="description" content="A list of your favorite ASMR artists and videos." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1 className="userFavorites-heading">Favorite Artists</h1>
      <div className="utilities-divider"> <span></span></div>
      <Row gutter={[{ xs: 16 }, { xs: 16, sm: 16, md: 18, lg: 20, xl: 24, xxl: 26}]}>
        {ArtistList.length > 0 ? ArtistList : 'You have not favorited any artists yet!'}
      </Row>
      <Row align='bottom' justify='end'>
        <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18}>
          <h1 className="userFavorites-heading">Favorite Videos</h1>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}>
          <Search
            className="userFavorites-filter-box"
            placeholder="Filter by keyword, tag, or artist."
            enterButton="Filter"
            onSearch={triggerListFilter}
          />
        </Col>
      </Row>
      <div className="utilities-divider"> <span></span></div>
      {signedInUser.accesskey &&
        <VideoList pagination={true} type='favorite' filterTerm={listFilter} />
      }
    </>
  )
}

export default UserFavorites;